import React, { Component } from "react";
import DataTable from "../../components/DataTable";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {  ExpandRows ,decodeFromHex} from "../../classes/Helper";
import { errorHandler } from "../../stores/helpers";
import SessionStore from "../../stores/SessionStore";

import HandyRustyStore from "../../stores/HandyRustyStore";


let canLoad = false;



function processRowData(data) {

  if (data.txInfo && data.txInfo.length > 0) {
    data.txInfoStr =  data.txInfo
    data.txInfo = JSON.parse(data.txInfo);
  }
  if (data.rxInfo) {
    
    data.RxInfo = JSON.parse(JSON.stringify(data.rxInfo))
  }

  if(data.frmPayload){
    let decoded = decodeFromHex(data.frmPayload,['nav'])
  
    data.devLAT = decoded.latitude
    data.devLON = decoded.longitude
  }
  
  return data;

}

function LoggerRow(props) {
  let data = props.data

 
  if (!data) {
    return null;
  }

 // const id = "J" + Math.ceil(Math.random() * 10000000000000000);

  data = processRowData(data)
  // let display;
  // if (props.expandAll) {
  //   display = 'table-row'
  // } else {
  //   display = 'none'
  // }

  return (
    <>
      <TableRow
        onClick={props.onClick}
        hover
        style={{cursor: 'pointer'}}>
        {/* <TableCell
          id={id} className={'cell '}
          onClick={props.handleToggleClick}>
          <Icon
            style={{fontSize: 15, verticalAlign: 'middle'}}
            className="fa fa-plus-circle"/>
        </TableCell> */}
        {props.fields && props.fields.map((field, i) => (
        <TableCell key={i}
                   className={'cell pd'}
                   style={(field.cell.style) ? field.cell.style(data,props) : undefined}>
          {field.cell.render(data)}
        </TableCell>))}
      </TableRow>
     

      </>)

}



class Table extends Component{
    constructor(){
        super();
      
        this.getPage = this.getPage.bind(this);
        this.getRow = this.getRow.bind(this);
        this.expandAllchange = this.expandAllchange.bind(this);
   
        this.state = {
            expandAll: false
        }

        if (SessionStore.user !== null) {
          canLoad = true;
        }else{
          SessionStore.on("userGot", () => {
            const userStore = SessionStore.getUser();
          
           
            if (userStore === null || userStore === undefined) {
              canLoad = false;
            }else{
              canLoad = true;
            }
          })
        }
       
    }

    expandAllchange(ev) {
        ev.persist();
    
        this.setState({expandAll: !this.state.expandAll}, () => {
          let icon = ev.target
          let arr = ['fa-minus-circle', 'fa-plus-circle']
          if (this.state.expandAll) {
            arr.reverse()
            ExpandRows(true)
          } else {
            ExpandRows(false)
          }
          icon.className = icon.className.replace(...arr);
        })
      }
  
    getRow(data, key,arr) {
         let query = SessionStore.getQueryString()
        let fCntFlag = false
        if(query&&query["devEUI"]!==undefined){
            if(arr[key+1] && arr[key].FCntUp-arr[key+1].FCntUp!==1){
              fCntFlag=true
            }
        }
        const clickRow = (e) =>{
          this.props.loadMap(data)
        }

        return (
          <LoggerRow 
          fCntFlag={fCntFlag}
          onClick={clickRow}
          key={key}
                     expandAll={this.state.expandAll}
                     fields={this.props.fields} data={data}/>
        );
      }
    getPage(limit, offset, callbackFunc) {

    
        let filter = {}
        filter.filters = {}

        let query = SessionStore.getQueryString()
      
        if(filter['filters']===undefined){
          filter['filters'] = {}
        }
        if(query){

          if(query["devEUI"]){
            filter['filters']["devEUI"]  = query["devEUI"]
            if(!SessionStore.isAdmin() && SessionStore.organizations.filter(el =>  el.isDeviceAdmin ).length > 1  ){
              SessionStore.globalSearch(filter['filters']["devEUI"] ,1,0,(resp)=>{ 
                if(resp.totalCount>0 && resp.result[0].organizationID!==SessionStore.getOrganizationID() ){
                    errorHandler( {message : "Device is out of scope current organization or not found."} )
                }
               
              })
              
            }
          }
          if(query['start']){
            filter['start'] = query['start']
          }
          if(query['end']){
            filter['end'] = query['end']
          }

        }
        
        if (canLoad===true) {
      
         // ExpandRows(this.state.expandAll, () => {
            if (filter.offset>1000 || !filter['filters']["devEUI"]){
              let obj = {};
              obj.totalCount =  0;
              obj.result =  [];
              callbackFunc(obj)
              return
            }
            canLoad = false
           filter['filters'].direction="RX"
            HandyRustyStore.getDeviceFrames(filter, limit, offset,(obj)=>{
              canLoad = true
              callbackFunc(obj)
            } )
         // })
        }
    }

    render() {

        return (
          <DataTable ref={this.props.tableRef}
          changePageEvent={this.props.changePageEvent}
          fields={this.props.fields}
          maxpage={false}
                     header={
                       <TableRow className={'head-container'}>
                         {/* <TableCell className={'cell'}>
                           <Icon
                             onClick={this.expandAllchange}
                             title={'Expand all'}
                             style={{
                               fontSize: 15,
                               verticalAlign: 'middle',
                               cursor: 'pointer'
                              }}
                             className={"fa fa-plus-circle"}/>
                         </TableCell> */}
    
                         {this.props.fields  && this.props.fields.map((field, i) => {
    
                           return (
                             <TableCell style={{paddingBottom: '10px'}}
                                        key={i}
                                        className={'header-name pd after'}>
                               {field.head.render()}
                             </TableCell>
                           )})
                        }
                       </TableRow>
                     }
                     getPage={this.getPage}
                     getRow={this.getRow}
          />
        );
      }
}

export default Table