import React from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import {Controlled as CodeMirror} from "react-codemirror2";
import "codemirror/mode/javascript/javascript";

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import KVForm from "../../components/KVForm";
import DurationField from "../../components/DurationField";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import NetworkServerStore from "../../stores/NetworkServerStore";
import { FormLabel } from "../../../node_modules/@material-ui/core";


const styles = {
  formLabel: {
    fontSize: 12,
  },
  codeMirror: {
    zIndex: 1,
  },
  headPaddings: {
    padding: '5px 20px 5px 20px',
  },
  flexCheckbox: {
    display: 'flex',
  },
  flexInnerContainerLeft: {
    paddingRight: '20px',
    width: '100%'
  },
  flexInnerContainerRight: {
    width: '100%'
  },
  formDefaultText:{
    fontSize:'16px',
    padding:'6px 0',
    opacity:0.4
  }
};

const defaultValues = () => {
  
  return  {
  joinAcceptDelay2:0,
  joinAcceptDelay1:0,
  supportsJoin : true,
  maxEIRP : 16,
  rx1Delay: -1,
  rx1DrOffset: -1,
  rx2Datarate: -1,
  rx2Freq: -1,
  rxDelay1:1,
  rxFreq2:869100000,
  factoryPresetFreqs: [868900000, 869100000],
  macVersion: '1.0.4',
  regParamsRevision: 'RP002-1.0.1',
  uplinkInterval: '300000s',
  fCntAutomaticReset: true,
  cmdSwitches: [
    {cid: 15, enabled: true},
    {cid: 3, enabled: true},
    {cid: 7, enabled: true},
    {cid: 8, enabled: true},
    {cid: 6, enabled: true},
    {cid: 4, enabled: true},
    {cid: 2, enabled: true},
    {cid: 5, enabled: true},
    {cid: 10, enabled: true},
    {cid: 9, enabled: true},
    {cid: 19, enabled: true},
    {cid: 17, enabled: true},
  ],
}}


class DeviceProfileForm extends FormComponent {
  constructor() {
    super();
    this.state = {
      tab: 0,
      tags: []
    };

    this.onTabChange = this.onTabChange.bind(this);
    this.getNetworkServerOptions = this.getNetworkServerOptions.bind(this);
    this.getMACVersionOptions = this.getMACVersionOptions.bind(this);
    this.getRxOptions = this.getRxOptions.bind(this);
    this.getRxJoinOptions = this.getRxJoinOptions.bind(this);
    this.getJoinOptions = this.getJoinOptions.bind(this);
    this.getRxJoinOption = this.getRxJoinOption.bind(this);
    this.getJoinOption = this.getJoinOption.bind(this);
    this.getRxOption = this.getRxOption.bind(this);
    this.getRegParamsOptions = this.getRegParamsOptions.bind(this);
    this.getPingSlotPeriodOptions = this.getPingSlotPeriodOptions.bind(this);
    this.getPayloadCodecOptions = this.getPayloadCodecOptions.bind(this);
    this.onCodeChange = this.onCodeChange.bind(this);
    this.setDefaults = this.setDefaults.bind(this);
    this.getCmdSwitchesByCID = this.getCmdSwitchesByCID.bind(this);
    this.getOptionSelected = this.getOptionSelected.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this.setKVArray(this.props.object || {});
    if(!this.props.object){
      this.setDefaults()
    }


  }

  componentWillUnmount(){
    this.setDefaults()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    if (prevProps.object !== this.props.object) {
      this.setKVArray(this.props.object || {});
    }

  }
  setDefaults(){
    this.setState({object:defaultValues()})
  }

  getNetworkServerOptions(search, callbackFunc) {
    NetworkServerStore.list(this.props.match.params.organizationID, 999, 0, resp => {
      const options = resp.result.map((ns, i) => {return {label: ns.name, value: ns.id}});
      callbackFunc(options);
    });
  }

  getMACVersionOptions(search, callbackFunc) {
    const macVersionOptions = [
      {value: "1.0.0", label: "1.0.0"},
      {value: "1.0.1", label: "1.0.1"},
      {value: "1.0.2", label: "1.0.2"},
      {value: "1.0.3", label: "1.0.3"},
      {value: "1.0.4", label: "1.0.4"},
      {value: "1.1.0", label: "1.1.0"},
    ];

    callbackFunc(macVersionOptions);
  }
  getRxOptions(search,callbackFunc){
    const rxOptions = [
      {value:-1,label: "Default"},
      {value:0,label: "0"},
      {value:1,label: "1"},
      {value:2,label: "2"},
      {value:3,label: "3"},
      {value:4,label: "4"},
      {value:5,label: "5"},
    ]
    callbackFunc(rxOptions);
  }
  getJoinOptions(search,callbackFunc){
    const joinOptions = [
      {value:0,label: "Default"},
      {value:1,label: "1"},
      {value:2,label: "2"},
      {value:3,label: "3"},
      {value:4,label: "4"},
      {value:5,label: "5"},
      {value:6,label: "6"},
      {value:7,label: "7"},
      {value:8,label: "8"},
      {value:9,label: "9"},
      {value:10,label: "10"},
      {value:11,label: "11"},
      {value:12,label: "12"},
      {value:13,label: "13"},
      {value:14,label: "14"},
      {value:15,label: "15"},
    ]
    callbackFunc(joinOptions);
  }
  getJoinOption(val,callbackFunc){
    this.getJoinOptions("",(arr)=>{
      arr.forEach((item)=>{
        if(item.value===val){
          callbackFunc({value:item.value,label:item.label})
        }
      })
    })
  }
  getRxJoinOptions(search,callbackFunc){
    const rxJoinOptions = [
      {value:-1,label: "Default"},
      {value:1,label: "1"},
      {value:2,label: "2"},
      {value:3,label: "3"},
      {value:4,label: "4"},
      {value:5,label: "5"},
      {value:6,label: "6"},
      {value:7,label: "7"},
      {value:8,label: "8"},
      {value:9,label: "9"},
      {value:10,label: "10"},
      {value:11,label: "11"},
      {value:12,label: "12"},
      {value:13,label: "13"},
      {value:14,label: "14"},
      {value:15,label: "15"},
    ]
    callbackFunc(rxJoinOptions);
  }
  getRxJoinOption(val,callbackFunc){
    this.getRxJoinOptions("",(arr)=>{
      arr.forEach((item)=>{
        if(item.value===val){
          callbackFunc({value:item.value,label:item.label})
        }
      })
    })
  }
  getRxOption(val,callbackFunc){
    this.getRxOptions("",(arr)=>{
      arr.forEach((item)=>{
        if(item.value===val){
          callbackFunc({value:item.value,label:item.label})
        }
      })
    })
  }
  getRegParamsOptions(search, callbackFunc) {
    const regParamsOptions = [
      {value: "A", label: "A"},
      {value: "B", label: "B"},
      {value: "RP002-1.0.0", label: "RP002-1.0.0"},
      {value: "RP002-1.0.1", label: "RP002-1.0.1"},
      {value: "RP002-1.0.2", label: "RP002-1.0.2"},
      {value: "RP002-1.0.3", label: "RP002-1.0.3"},
    ];

    callbackFunc(regParamsOptions);
  }

  getPingSlotPeriodOptions(search, callbackFunc) {
    const pingSlotPeriodOptions = [
      {value: 32 * 1, label: "every second"},
      {value: 32 * 2, label: "every 2 seconds"},
      {value: 32 * 4, label: "every 4 seconds"},
      {value: 32 * 8, label: "every 8 seconds"},
      {value: 32 * 16, label: "every 16 seconds"},
      {value: 32 * 32, label: "every 32 seconds"},
      {value: 32 * 64, label: "every 64 seconds"},
      {value: 32 * 128, label: "every 128 seconds"},
    ];

    callbackFunc(pingSlotPeriodOptions);
  }

  getPayloadCodecOptions(search, callbackFunc) {
    const payloadCodecOptions = [
      {value: "", label: "None"},
      {value: "CAYENNE_LPP", label: "Cayenne LPP"},
      {value: "CUSTOM_JS", label: "Custom JavaScript codec functions"},
    ];

    callbackFunc(payloadCodecOptions);
  }

  onCodeChange(field, editor, data, newCode) {
    let object = this.state.object;
    object[field] = newCode;
    this.setState({
      object: object,
    });
  }

  onTabChange(e, v) {
    this.setState({
      tab: v,
    });
  }
  
  onChange(e) {
    super.onChange(e);
    if (e.target.id === "networkServerID") {
      this.props.clearErrorNetworkServerID();
    }
    let object = this.state.object;
    if (e.target.id === "factoryPresetFreqsStr") {
      let freqsStr = e.target.value.split(",");
      object["factoryPresetFreqs"] = freqsStr.map((f, i) => parseInt(f, 10));
    }
    if (e.target.id === "requestRejoinParamSetup") {
      object.cmdSwitches[this.getCmdSwitchesByCID(15)].enabled = e.target.checked;
    }
    if (e.target.id === "requestADRChange") {
      object.cmdSwitches[this.getCmdSwitchesByCID(3)].enabled = e.target.checked;
    }
    if (e.target.id === "requestCustomChannelReconfiguration") {
      object.cmdSwitches[this.getCmdSwitchesByCID(7)].enabled = e.target.checked;
    }
    if (e.target.id === "rxTimingSetupReq") {
      object.cmdSwitches[this.getCmdSwitchesByCID(8)].enabled = e.target.checked;
    }
    if (e.target.id === "requestDevStatus") {
      object.cmdSwitches[this.getCmdSwitchesByCID(6)].enabled = e.target.checked;
    }
    if (e.target.id === "dutyCycleReq") {
      object.cmdSwitches[this.getCmdSwitchesByCID(4)].enabled = e.target.checked;
    }
    if (e.target.id === "linkCheckReq") {
      object.cmdSwitches[this.getCmdSwitchesByCID(2)].enabled = e.target.checked;
    }
    if (e.target.id === "setRXParameters") {
      object.cmdSwitches[this.getCmdSwitchesByCID(5)].enabled = e.target.checked;
    }
    if (e.target.id === "dlChannelReq") {
      object.cmdSwitches[this.getCmdSwitchesByCID(10)].enabled = e.target.checked;
    }
    if (e.target.id === "setTXParameters") {
      object.cmdSwitches[this.getCmdSwitchesByCID(9)].enabled = e.target.checked;
    }
    if (e.target.id === "beaconFreqReq") {
      object.cmdSwitches[this.getCmdSwitchesByCID(19)].enabled = e.target.checked;
    }
    if (e.target.id === "setPingSlotParameters") {
      object.cmdSwitches[this.getCmdSwitchesByCID(17)].enabled = e.target.checked;
    }
    if (e.target.id === "supportsJoin" && !e.target.checked) {
      object.fCntAutomaticReset = true
      object.factoryPresetFreqsStr = [868900000, 869100000].join(', ')
      object.rxDelay1 = 1
      object.rxDROffset1 = 0
      object.rxDataRate2 = 0
      object.rxFreq2 = 869100000
      object.cmdSwitches[this.getCmdSwitchesByCID(17)].enabled = e.target.checked;
    }
    this.setState({
      object: object,
    });
  }

  setKVArray = (props) => {
    let tags = [];

    if (props.tags !== undefined) {
      for (let key in props.tags) {
        tags.push({key: key, value: props.tags[key]});
      }
    }

    this.setState({
      tags: tags,
    });
  }

  getCmdSwitchesByCID = (cid) => {
    if (this.state.object && this.state.object.cmdSwitches && this.state.object.cmdSwitches.length) {
      return this.state.object.cmdSwitches.findIndex(sw => sw.cid === cid);
    }
  }
  getOptionSelected(option, value){
   
    if (!value){return true}
    return option.value === value.value
  }
  setDefaultFreq = ()=>{
    let object = this.state.object
    object.rx2Freq = 0;
    this.setState({
      object: object,
    });
  }
  render() {
    if (this.state.object === undefined) {
      return null;
    }


    let factoryPresetFreqsStr = "";
    if (this.state.object.factoryPresetFreqsStr !== undefined) {
      factoryPresetFreqsStr = this.state.object.factoryPresetFreqsStr;
    } else if (this.state.object.factoryPresetFreqs !== undefined) {
      factoryPresetFreqsStr = this.state.object.factoryPresetFreqs.join(", ");
    }

    const codeMirrorOptions = {
      lineNumbers: true,
      mode: "javascript",
      theme: "default",
    };
    
    let payloadEncoderScript = this.state.object.payloadEncoderScript;
    let payloadDecoderScript = this.state.object.payloadDecoderScript;

    if (payloadEncoderScript === "" || payloadEncoderScript === undefined) {
      payloadEncoderScript = `// Encode encodes the given object into an array of bytes.
//  - fPort contains the LoRaWAN fPort number
//  - obj is an object, e.g. {"temperature": 22.5}
//  - variables contains the device variables e.g. {"calibration": "3.5"} (both the key / value are of type string)
// The function must return an array of bytes, e.g. [225, 230, 255, 0]
function Encode(fPort, obj, variables) {
  return [];
}`;
    }

    if (payloadDecoderScript === "" || payloadDecoderScript === undefined) {
      payloadDecoderScript = `// Decode decodes an array of bytes into an object.
//  - fPort contains the LoRaWAN fPort number
//  - bytes is an array of bytes, e.g. [225, 230, 255, 0]
//  - variables contains the device variables e.g. {"calibration": "3.5"} (both the key / value are of type string)
// The function must return an object, e.g. {"temperature": 22.5}
function Decode(fPort, bytes, variables) {
  return {};
}`;
    }

    const tags = this.state.tags.map((obj, i) => <KVForm key={i} index={i} object={obj} onChange={this.onChangeKV("tags")} onDelete={this.onDeleteKV("tags")} />);

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >
        <Tabs value={this.state.tab} onChange={this.onTabChange} indicatorColor="primary">
          <Tab label="General" />
          <Tab label="Join (OTAA / ABP)" />
          <Tab label="Class-B" />
          <Tab label="Class-C" />
          <Tab label="Codec" />
          <Tab label="Tags" />
          <Tab label="LoRaWAN Configuration" />
        </Tabs>

        {this.state.tab === 0 && <div>
          <TextField
            id="name"
            label="Device-profile name"
            margin="normal"
            value={this.state.object.name || ""}
            onChange={this.onChange}
            helperText="A name to identify the device-profile."
            required
            fullWidth
            disabled={this.props.disabled}
          />
          {this.props.update && <TextField
            id="rfRegion"
            label="Frequency plan"
            margin="normal"
            value={this.state.object.rfRegion || ""}
            fullWidth
            disabled={this.props.disabled}
            focused={false}
            onFocus={(e)=>{
              e.currentTarget.blur()
            }}
          />}
          {!this.props.update && <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>Frequency plan</FormLabel>
            <AutocompleteSelect
              id="networkServerID"
              label="Select frequency plan"
              value={this.state.object.networkServerID || ""}
              onChange={this.onChange}
              getOptions={this.getNetworkServerOptions}
              getOptionSelected={this.getOptionSelected}
            />
            <FormHelperText>
              The frequency plan on which this device-profile will be provisioned. After creating the device-profile, this value can't be changed.
            </FormHelperText>
            {this.props.errorNetworkServerID && (<span style={{color: "red"}}>Frequency plan can not be empty</span>)}
          </FormControl>}
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>LoRaWAN MAC version</FormLabel>
            <AutocompleteSelect
              id="macVersion"
              label="Select LoRaWAN MAC version"
              value={this.state.object.macVersion || ""}
              onChange={this.onChange}
              getOptions={this.getMACVersionOptions}
              disabled={this.props.disabled}
              getOptionSelected={this.getOptionSelected}
            />
            <FormHelperText>
              The LoRaWAN MAC version supported by the device.
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>LoRaWAN Regional Parameters revision</FormLabel>
            <AutocompleteSelect
              id="regParamsRevision"
              label="Select LoRaWAN Regional Parameters revision"
              value={this.state.object.regParamsRevision || ""}
              onChange={this.onChange}
              getOptions={this.getRegParamsOptions}
              disabled={this.props.disabled}
              getOptionSelected={this.getOptionSelected}
            />
            <FormHelperText>
                Revision of the Regional Parameters specification supported by the device.
            </FormHelperText>
          </FormControl>

          <DurationField
            id="uplinkInterval"
            label="Uplink interval (seconds)"
            helperText="The expected interval in seconds in which the device sends uplink messages. This is used to determine if a device is active or inactive."
            value={this.state.object.uplinkInterval}
            onChange={this.onChange}
            disabled={this.props.disabled}
          />

          <TextField
            id="maxEIRP"
            label="Max EIRP (dBm)"
            type="number"
            InputProps={{ inputProps: { min: -1, max: 16 } }}
            margin="normal"
            value={this.state.object.maxEIRP}
            onChange={this.onChange}
            // helperText="Maximum EIRP supported by the device."
            fullWidth
            disabled={this.props.disabled}
          />
        </div>}

        {this.state.tab === 1 && <div>
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              label="Device supports OTAA"
              control={
                <Checkbox
                  id="supportsJoin"
                  checked={!!this.state.object.supportsJoin}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormControl>
          {!this.state.object.supportsJoin && <TextField
            id="rxDelay1"
            label="RX1 delay"
            type="number"
            margin="normal"
            value={this.state.object.rxDelay1 || 0}
            onChange={this.onChange}
            helperText="RX1 delay (valid values are 0 - 15)."
            required
            fullWidth
            disabled={this.props.disabled}
          />}
          {!this.state.object.supportsJoin && <TextField
            id="rxDROffset1"
            label="RX1 data-rate offset"
            type="number"
            margin="normal"
            value={this.state.object.rxDROffset1 || 0}
            onChange={this.onChange}
            helperText="Please refer the LoRaWAN Regional Parameters specification for valid values."
            required
            fullWidth
            disabled={this.props.disabled}
          />}
          {!this.state.object.supportsJoin && <TextField
            id="rxDataRate2"
            label="RX2 data-rate"
            type="number"
            margin="normal"
            value={this.state.object.rxDataRate2 || 0}
            onChange={this.onChange}
            helperText="Please refer the LoRaWAN Regional Parameters specification for valid values."
            required
            fullWidth
            disabled={this.props.disabled}
          />}
          {!this.state.object.supportsJoin && <TextField
            id="rxFreq2"
            label="RX2 channel frequency (Hz)"
            type="number"
            margin="normal"
            value={this.state.object.rxFreq2 || 0}
            onChange={this.onChange}
            required
            fullWidth
            disabled={this.props.disabled}
          />}
          {!this.state.object.supportsJoin && <TextField
            id="factoryPresetFreqsStr"
            label="Factory-preset frequencies (Hz)"
            margin="normal"
            value={factoryPresetFreqsStr}
            onChange={this.onChange}
            helperText="List of factory-preset frequencies (Hz), comma separated."
            required
            fullWidth
            disabled={this.props.disabled}
          />}

          {!this.state.object.supportsJoin && 
           <FormControl fullWidth margin="normal">
                
                 <FormControlLabel
                    label="Allowed ABP automatic reset"
                    control={
                      <Checkbox
                        id="fCntAutomaticReset"
                        checked={!!this.state.object.fCntAutomaticReset}
                        onChange={this.onChange}
                        color="primary"
                        disabled={this.props.disabled}
                      />
                    }
                  />
            </FormControl> 
          }          
        </div>}

        {this.state.tab === 2 && <div>
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              label="Device supports Class-B"
              control={
                <Checkbox
                  id="supportsClassB"
                  checked={!!this.state.object.supportsClassB}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormControl>

          {this.state.object.supportsClassB && <TextField
            id="classBTimeout"
            label="Class-B confirmed downlink timeout"
            type="number"
            margin="normal"
            value={this.state.object.classBTimeout || 0}
            onChange={this.onChange}
            helperText="Class-B timeout (in seconds) for confirmed downlink transmissions."
            required
            fullWidth
            disabled={this.props.disabled}
          />}
          {this.state.object.supportsClassB && <FormControl
              fullWidth
              margin="normal"
            >
              <FormLabel className={this.props.classes.formLabel} required>Class-B ping-slot periodicity</FormLabel>
              <AutocompleteSelect
                id="pingSlotPeriod"
                label="Select Class-B ping-slot periodicity"
                value={this.state.object.pingSlotPeriod || ""}
                onChange={this.onChange}
                getOptions={this.getPingSlotPeriodOptions}
                disabled={this.props.disabled}
                getOptionSelected={this.getOptionSelected}
              />
              <FormHelperText>Class-B ping-slot periodicity.</FormHelperText>
          </FormControl>}
          {this.state.object.supportsClassB && <TextField
            id="pingSlotDR"
            label="Class-B ping-slot data-rate"
            type="number"
            margin="normal"
            value={this.state.object.pingSlotDR || 0}
            onChange={this.onChange}
            required
            fullWidth
            disabled={this.props.disabled}
          />}
          {this.state.object.supportsClassB && <TextField
            id="pingSlotFreq"
            label="Class-B ping-slot frequency (Hz)"
            type="number"
            margin="normal"
            value={this.state.object.pingSlotFreq || 0}
            onChange={this.onChange}
            required
            fullWidth
            disabled={this.props.disabled}
          />}
        </div>}

        {this.state.tab === 3 && <div>
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              label="Device supports Class-C"
              control={
                <Checkbox
                  id="supportsClassC"
                  checked={!!this.state.object.supportsClassC}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
            <FormHelperText>Select this option when the device will operate as Class-C device immediately after activation. In case it sends a DeviceModeInd mac-command when it changes to Class-C, do not select this option.</FormHelperText>
          </FormControl>

          <TextField
            id="classCTimeout"
            label="Class-C confirmed downlink timeout"
            type="number"
            margin="normal"
            value={this.state.object.classCTimeout || 0}
            onChange={this.onChange}
            helperText="Class-C timeout (in seconds) for confirmed downlink transmissions."
            required
            fullWidth
            disabled={this.props.disabled}
          />
        </div>}

        {this.state.tab === 4 && <div>
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel}>Payload codec</FormLabel>
            <AutocompleteSelect
              id="payloadCodec"
              label="Select payload codec"
              value={this.state.object.payloadCodec || ""}
              onChange={this.onChange}
              getOptions={this.getPayloadCodecOptions}
              disabled={this.props.disabled}
              getOptionSelected={this.getOptionSelected}
            />
            <FormHelperText>
              By defining a payload codec, Application Server can encode and decode the binary device payload for you.
            </FormHelperText>
          </FormControl>

          {this.state.object.payloadCodec === "CUSTOM_JS" && <FormControl fullWidth margin="normal">
            <CodeMirror
              value={payloadDecoderScript}
              options={codeMirrorOptions}
              onBeforeChange={this.onCodeChange.bind(this, 'payloadDecoderScript')}
              className={this.props.classes.codeMirror}
            />
            <FormHelperText>
              The function must have the signature <strong>function Decode(fPort, bytes)</strong> and must return an object.
              Application Server will convert this object to JSON.
            </FormHelperText>
          </FormControl>}
          {this.state.object.payloadCodec === "CUSTOM_JS" && <FormControl fullWidth margin="normal">
            <CodeMirror
              value={payloadEncoderScript}
              options={codeMirrorOptions}
              onBeforeChange={this.onCodeChange.bind(this, 'payloadEncoderScript')}
              className={this.props.classes.codeMirror}
            />
            <FormHelperText>
              The function must have the signature <strong>function Encode(fPort, obj)</strong> and must return an array
              of bytes.
            </FormHelperText>
          </FormControl>}
        </div>}

        {this.state.tab === 5 && <div>
          <FormControl fullWidth margin="normal">
            <Typography variant="body1">
              Tags can be used to store additional key/value data.
            </Typography>
            {tags}
          </FormControl>
          <Button variant="outlined" onClick={this.addKV("tags")} disabled={this.props.disabled}>Add tag</Button>
        </div>}

        {this.state.tab === 6 && <div>
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>MAC RX1 delay (sec.)</FormLabel>
            <AutocompleteSelect
              id="rx1Delay"
              label="MAC RX1 delay (sec.)"
              value={this.state.object.rx1Delay || 0}
              onChange={this.onChange}
              getOptions={this.getRxJoinOptions}
              getOption={this.getRxJoinOption}
              getOptionSelected={this.getOptionSelected}
              disabled={this.props.disabled}
              required
            />
          
          </FormControl>
         
          <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>MAC RX2 delay (sec.)</FormLabel>
            <div  className={this.props.classes.formDefaultText}>{(this.state.object.rx1Delay!==undefined&&this.state.object.rx1Delay>-1) ? (this.state.object.rx1Delay + 1) : 'Default'}</div>          
          </FormControl>
        

        <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>MAC RX1 Data Rate offset</FormLabel>
          <AutocompleteSelect
            id="rx1DrOffset"
            label="MAC RX1 Data Rate offset"
            value={this.state.object.rx1DrOffset || 0}
            onChange={this.onChange}
            getOptions={this.getRxOptions}
            getOption={this.getRxOption}
            getOptionSelected={this.getOptionSelected}
            disabled={this.props.disabled}
          />
         
        </FormControl>
        <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>MAC RX2 Data Rate</FormLabel>
          <AutocompleteSelect
            id="rx2Datarate"
            label="MAC RX2 Data Rate"
            value={this.state.object.rx2Datarate || 0}
            onChange={this.onChange}
            getOptions={this.getRxOptions}
            getOption={this.getRxOption}
            disabled={this.props.disabled}
            getOptionSelected={this.getOptionSelected}
          />
         
        </FormControl>


        {(this.state.object.rx2Freq===-1)?( <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>MAC RX2 Frequency</FormLabel>
            <div  className={this.props.classes.formDefaultText} onClick={this.setDefaultFreq}>{"Default"}</div>
          </FormControl>): <TextField
            id="rx2Freq"
            label="MAC RX2 Frequency"
            type="number"
            margin="normal"
            value={this.state.object.rx2Freq}
            InputProps={{ inputProps: { min: -1 ,max:870000000 } }}
            onChange={this.onChange}
            fullWidth
            disabled={this.props.disabled}
          />}
         

          {this.state.object.supportsJoin &&  <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>Join Accept Delay 1 (sec.)</FormLabel>
          <AutocompleteSelect
            id="joinAcceptDelay1"
            label="Join Accept Delay 1 (sec.)"
            value={this.state.object.joinAcceptDelay1 || 0}
            onChange={this.onChange}
            getOptions={this.getJoinOptions}
            getOption={this.getJoinOption}
            getOptionSelected={this.getOptionSelected}
            disabled={this.props.disabled}
          />
         
        </FormControl>}

          {this.state.object.supportsJoin &&  <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>Join Accept Delay 2 (sec.)</FormLabel>
            <div  className={this.props.classes.formDefaultText}>{(this.state.object.joinAcceptDelay1!==undefined&&this.state.object.joinAcceptDelay1>0) ? (this.state.object.joinAcceptDelay1 + 1) : 'Default'}</div>          
          </FormControl> }

          <h3 className={this.props.classes.headPaddings}>Supported MAC command</h3>

          <div className={this.props.classes.flexCheckbox}>
          <div>

          <FormControl fullWidth>
            <FormControlLabel
              label="RejoinParamSetupReq"
              control={
                <Checkbox
                  id="requestRejoinParamSetup"
                  checked={!!this.state.object.cmdSwitches[this.getCmdSwitchesByCID(15)].enabled}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormControl>

          <FormControl fullWidth>
            <FormControlLabel
              label="LinkADRReq"
              control={
                <Checkbox
                  id="requestADRChange"
                  checked={!!this.state.object.cmdSwitches[this.getCmdSwitchesByCID(3)].enabled}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormControl>

          <FormControl fullWidth>
            <FormControlLabel
              label="NewChannelReq"
              control={
                <Checkbox
                  id="requestCustomChannelReconfiguration"
                  checked={!!this.state.object.cmdSwitches[this.getCmdSwitchesByCID(7)].enabled}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormControl>

          <FormControl fullWidth>
            <FormControlLabel
              label="RxTimingSetupReq"
              control={
                <Checkbox
                  id="rxTimingSetupReq"
                  checked={!!this.state.object.cmdSwitches[this.getCmdSwitchesByCID(8)].enabled}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormControl>

          <FormControl fullWidth>
            <FormControlLabel
              label="DevStatusReq"
              control={
                <Checkbox
                  id="requestDevStatus"
                  checked={!!this.state.object.cmdSwitches[this.getCmdSwitchesByCID(6)].enabled}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormControl>

          <FormControl fullWidth>
            <FormControlLabel
              label="DutyCycleReq"
              control={
                <Checkbox
                  id="dutyCycleReq"
                  checked={!!this.state.object.cmdSwitches[this.getCmdSwitchesByCID(4)].enabled}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormControl>
          </div>
          <div>
          <FormControl fullWidth>
            <FormControlLabel
              label="LinkCheckReq"
              control={
                <Checkbox
                  id="linkCheckReq"
                  checked={!!this.state.object.cmdSwitches[this.getCmdSwitchesByCID(2)].enabled}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormControl>

          <FormControl fullWidth>
            <FormControlLabel
              label="RxParamSetupReq"
              control={
                <Checkbox
                  id="setRXParameters"
                  checked={!!this.state.object.cmdSwitches[this.getCmdSwitchesByCID(5)].enabled}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormControl>

          <FormControl fullWidth>
            <FormControlLabel
              label="DlChannelReq"
              control={
                <Checkbox
                  id="dlChannelReq"
                  checked={!!this.state.object.cmdSwitches[this.getCmdSwitchesByCID(10)].enabled}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormControl>

          <FormControl fullWidth>
            <FormControlLabel
              label="TxParamSetupReq"
              control={
                <Checkbox
                  id="setTXParameters"
                  checked={!!this.state.object.cmdSwitches[this.getCmdSwitchesByCID(9)].enabled}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormControl>

          <FormControl fullWidth>
            <FormControlLabel
              label="BeaconFreqReq"
              control={
                <Checkbox
                  id="beaconFreqReq"
                  checked={!!this.state.object.cmdSwitches[this.getCmdSwitchesByCID(19)].enabled}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormControl>
          <FormControl fullWidth>
            <FormControlLabel
              label="PingSlotChannelReq"
              control={
                <Checkbox
                  id="setPingSlotParameters"
                  checked={!!this.state.object.cmdSwitches[this.getCmdSwitchesByCID(17)].enabled}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormControl>
          </div>
          </div>
        </div>}
      </Form>
    );
  }
}

export default withStyles(styles)(DeviceProfileForm);
