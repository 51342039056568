import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import {Controlled as CodeMirror} from "react-codemirror2";
import "codemirror/mode/javascript/javascript";

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import {base64ToHex ,hexToBase} from "../../components/JSONTree";
import { CheckHex} from '../../classes/Helper'

const styles = {
  codeMirror: {
    zIndex: 1,
  },
};



class DeviceQueueItemForm extends FormComponent {
  constructor() {
    super();

    this.state = {
      tab: 0,
      errors:{}
    };
    this.changeHex = this.changeHex.bind(this)
  }

  onTabChange = (e, v) => {
    this.setState({
      tab: v,
    });
  }

  onCodeChange = (field, editor, data, newCode) => {
    let object = this.state.object;
    object[field] = newCode;
    this.setState({
      object: object,
    });
  }
  changeHex(e){
   
    this.setState({hex:e.target.value})
    let val = (e.target.value!=='')?hexToBase( e.target.value ):'';
    let event = {
      target:{
        value :val,
        id: 'data',
        type:'text'
      }
    }
  this.onChange(event)
  }
  componentDidUpdate(prevProps,prevstate) {
    super.componentDidUpdate(prevProps);
    /*if(this.state.hex===prevstate.hex){
      if(prevProps.object.data!=undefined && this.state.hex!=base64ToHex(prevProps.object.data)){
        let hex = base64ToHex(prevProps.object.data).toUpperCase();
        this.setState({hex:(prevProps.object.data)?hex:''},()=>{
          console.log('updated' , this.state.hex)
        })
      }
    }*/

  }
  onSubmit(e){
   

    if (!CheckHex(this.state.hex)){
      e.preventDefault();
      let errors = this.state.errors
      if (!errors["hex"]){
        errors["hex"] = true
      }
      this.setState({errors:errors})
      return
    }else{
      let errors = this.state.errors
      errors["hex"] = false
      this.setState({errors:errors})
    }
    super.onSubmit(e)
  }
  render() {
    if (this.state.object === undefined) {
      return null;
    }

    const codeMirrorOptions = {
      lineNumbers: true,
      mode: "javascript",
      theme: "default",
    };

    let objectCode = this.state.object.jsonObject;
    if (objectCode === "" || objectCode === undefined) {
      objectCode = `{}`
    }

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >
        <TextField
          id="fPort"
          label="Port"
          margin="normal"
          value={this.state.object.fPort || ""}
          onChange={this.onChange}
          helperText="Please note that the fPort value must be > 0."
          required
          fullWidth
          type="number"
          disabled={this.props.disabled}
        />
          <TextField
          id="ttl"
          label="TTL"
          type="number"
          margin="normal"
          InputProps={{ inputProps: { min: 60, max: 1209600 } }}
          value={this.state.object.ttl || ""}
          onChange={this.onChange}
          helperText="ttl must be >= 60 and <= 1209600"
          fullWidth
          disabled={this.props.disabled}
        />
        <FormControl fullWidth margin="normal">
          <FormControlLabel
            label="Confirmed downlink"
            control={
              <Checkbox
                id="confirmed"
                checked={!!this.state.object.confirmed}
                onChange={this.onChange}
                color="primary"
                disabled={this.props.disabled}
              />
            }
          />
        </FormControl>
        <Tabs value={this.state.tab} onChange={this.onTabChange} indicatorColor="primary">
          <Tab label="HEX" />
          <Tab label="Base64 encoded" />
          <Tab label="JSON object" />
        </Tabs>
        {this.state.tab === 0 && <TextField
            id="hex"
            label="HEX"
            margin="normal"
            value={(this.state.object.data)?(this.state.hex || ""):""}
            error={this.state.errors["hex"]||undefined}
            onChange={(e)=>{
              e.target.value = e.target.value.toUpperCase() ;

              this.changeHex(e)
            }}
            required
            fullWidth
            disabled={this.props.disabled}
            helperText={"HEX field must be english, be even, expects A-Fa-f0-9 chars only"}
        />}
        {this.state.tab === 1 && <TextField
          id="data"
          label="Base64 encoded string"
          margin="normal"
          value={this.state.object.data || ""}
          onChange={(e)=>{
            let val = e.currentTarget.value;
            let hex = base64ToHex(val).toUpperCase();
            this.setState({hex:hex})
            this.onChange(e)
          }}
          required
          fullWidth
          disabled={this.props.disabled}
        />}
        {this.state.tab === 2 && <FormControl fullWidth margin="normal">
          <CodeMirror
            value={objectCode}
            className={this.props.classes.codeMirror}
            options={codeMirrorOptions}
            onBeforeChange={this.onCodeChange.bind(this, 'jsonObject')}
          />
          <FormHelperText>
            The device must be configured with a Device Profile supporting a Codec which is able to encode the given (JSON) payload.
          </FormHelperText>
        </FormControl>}

      </Form>
    );
  }
}

export default withStyles(styles)(DeviceQueueItemForm);

