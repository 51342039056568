import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { withStyles } from "@material-ui/core/styles";
import Typograhy from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import FormComponent from "../../classes/FormComponent";
import AESKeyField from "../../components/AESKeyField";
import DevAddrField from "../../components/DevAddrField";
import Form from "../../components/Form";
import DeviceStore from "../../stores/DeviceStore";
import theme from "../../theme";


const styles = {
  link: {
    color: theme.palette.primary.main,
  },
  formLabel:{
    fontSize:'13px'
  }
};


class LW10DeviceActivationForm extends FormComponent {
  constructor() {
    super();
    this.getRandomDevAddr = this.getRandomDevAddr.bind(this);
  }

  getRandomDevAddr(cb) {
    DeviceStore.getRandomDevAddr(this.props.match.params.devEUI, resp => {
      cb(resp.devAddr);
    });
  }

  render() {
    if (this.state.object === undefined) {
      return(<div></div>);
    }
   
   
    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
      >
        <DevAddrField
          id="devAddr"
          label="Device address"
          margin="normal"
          value={this.state.object.devAddr || ""}
          onChange={this.onChange}
          disabled={this.props.disabled}
          randomFunc={this.getRandomDevAddr}
          helperText="While any device address can be entered, please note that a LoRaWAN compliant device address consists of an AddrPrefix (derived from the NetID) + NwkAddr."
          fullWidth
          required
          random
        />
        <AESKeyField
          id="nwkSEncKey"
          label="Network session key (LoRaWAN 1.0)"
          margin="normal"
          value={this.state.object.nwkSEncKey || ""}
          onChange={this.onChange}
          disabled={this.props.disabled}
          fullWidth
          required
          random
        />
        <AESKeyField
          id="appSKey"
          label="Application session key (LoRaWAN 1.0)"
          margin="normal"
          value={this.state.object.appSKey || ""}
          onChange={this.onChange}
          disabled={this.props.disabled}
          required
          fullWidth
          random
        />
        <TextField
          id="fCntUp"
          label="Uplink frame-counter"
          margin="normal"
          type="number"
          value={this.state.object.fCntUp || 0}
          onChange={this.onChange}
          disabled={this.props.disabled}
          required
          fullWidth
        />
        <TextField
          id="nFCntDown"
          label="Downlink frame-counter (network)"
          margin="normal"
          type="number"
          value={this.state.object.nFCntDown || 0}
          onChange={this.onChange}
          disabled={this.props.disabled}
          required
          fullWidth
        />

        <TextField
          id="RX1DROffset"
          label="RX1DROffset"
          margin="normal"
          value={this.state.object.RX1DROffset || 0}
          disabled
          fullWidth
        />
        <TextField
          id="RX2DR"
          label="RX2DR"
          margin="normal"
          value={this.state.object.RX2DR || 0}
          disabled
          fullWidth
        />
        <TextField
          id="RX2Frequency"
          label="RX2Frequency"
          margin="normal"
          value={this.state.object.RX2Frequency || 0}
          disabled
          fullWidth
        />
         <TextField
          id="TXPowerIndex"
          label="TXPowerIndex"
          margin="normal"
          value={this.state.object.TXPowerIndex || 0}
          disabled
          fullWidth
        />
         <TextField
          id="DR"
          label="DR"
          margin="normal"
          value={this.state.object.DR || 0}
          disabled
          fullWidth
        />
         <TextField
          id="ADR"
          label="ADR"
          margin="normal"
          value={(this.state.object.ADR)?'true':'false'}
          disabled
          fullWidth
        />
         <TextField
          id="NbTrans"
          label="NbTrans"
          margin="normal"
          value={this.state.object.nbTrans || 0}
          disabled
          fullWidth
        />
         <TextField
          id="EnabledUplinkChannels"
          label="EnabledUplinkChannels"
          margin="normal"
          value={(Array.isArray(this.state.object.enabledUplinkChannels )&&this.state.object.enabledUplinkChannels.length>0)?this.state.object.enabledUplinkChannels.join(', '):""}
          disabled
          fullWidth
        />
      
       {this.state.object.extraUplinkChannels&&<FormControl fullWidth margin="normal">
         <FormLabel className={this.props.classes.formLabel} disabled filled>ExtraUplinkChannels</FormLabel>
         {Object.keys(this.state.object.extraUplinkChannels).sort((a,b)=>{
            if (this.state.object.extraUplinkChannels[a].index > this.state.object.extraUplinkChannels[b].index) {
              return 1;
            }
            if (this.state.object.extraUplinkChannels[a].index < this.state.object.extraUplinkChannels[b].index) {
              return -1;
            }
          
            return 0;
         }).map((el,i)=>{
           let text  = `${i}. Frequency: ${this.state.object.extraUplinkChannels[el].frequency} MinDR: ${this.state.object.extraUplinkChannels[el].minDR} MaxDR: ${this.state.object.extraUplinkChannels[el].maxDR}` 
        return  <TextField
          margin="normal"
          value={text}
          disabled
          fullWidth
        />
         })}
       
        </FormControl>}
      </Form>
    );
  }
}


class LW11DeviceActivationForm extends FormComponent {
  constructor() {
    super();
    this.getRandomDevAddr = this.getRandomDevAddr.bind(this);
  }

  getRandomDevAddr(cb) {
    DeviceStore.getRandomDevAddr(this.props.match.params.devEUI, resp => {
      cb(resp.devAddr);
    });
  }

  render() {
    // if (this.state.object === undefined) {
    //   return(<div></div>);
    // }

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
      >
        <DevAddrField
          id="devAddr"
          label="Device address"
          margin="normal"
          value={this.state.object.devAddr || ""}
          onChange={this.onChange}
          disabled={this.props.disabled}
          randomFunc={this.getRandomDevAddr}
          fullWidth
          required
          random
        />
        <AESKeyField
          id="nwkSEncKey"
          label="Network session encryption key"
          margin="normal"
          value={this.state.object.nwkSEncKey || ""}
          onChange={this.onChange}
          disabled={this.props.disabled}
          fullWidth
          required
          random
        />
        <AESKeyField
          id="sNwkSIntKey"
          label="Serving network session integrity key"
          margin="normal"
          value={this.state.object.sNwkSIntKey || ""}
          onChange={this.onChange}
          disabled={this.props.disabled}
          fullWidth
          required
          random
        />
        <AESKeyField
          id="fNwkSIntKey"
          label="Forwarding network session integrity key"
          margin="normal"
          value={this.state.object.fNwkSIntKey || ""}
          onChange={this.onChange}
          disabled={this.props.disabled}
          fullWidth
          required
          random
        />
        <AESKeyField
          id="appSKey"
          label="Application session key"
          margin="normal"
          value={this.state.object.appSKey || ""}
          onChange={this.onChange}
          disabled={this.props.disabled}
          required
          fullWidth
          random
        />
        <TextField
          id="fCntUp"
          label="Uplink frame-counter"
          margin="normal"
          type="number"
          value={this.state.object.fCntUp || 0}
          onChange={this.onChange}
          disabled={this.props.disabled}
          required
          fullWidth
        />
        <TextField
          id="nFCntDown"
          label="Downlink frame-counter (network)"
          margin="normal"
          type="number"
          value={this.state.object.nFCntDown || 0}
          onChange={this.onChange}
          disabled={this.props.disabled}
          required
          fullWidth
        />
        <TextField
          id="aFCntDown"
          label="Downlink frame-counter (application)"
          margin="normal"
          type="number"
          value={this.state.object.aFCntDown || 0}
          onChange={this.onChange}
          disabled={this.props.disabled}
          required
          fullWidth
        />
      </Form>
    );
  }
}


LW10DeviceActivationForm = withStyles(styles)(LW10DeviceActivationForm);
LW11DeviceActivationForm = withStyles(styles)(LW11DeviceActivationForm);


class DeviceActivation extends Component {
  constructor() {
    super();
    this.state = {};
    this.onSubmit = this.onSubmit.bind(this);
    this.setDeviceActivation = this.setDeviceActivation.bind(this);
  }
  
  componentDidMount() {
    this.setDeviceActivation()
  }

  onSubmit(deviceActivation) {
    let act = deviceActivation;
    act.devEUI = this.props.match.params.devEUI;

    if (this.props.deviceProfile.macVersion.startsWith("1.0")) {
      act.fNwkSIntKey = act.nwkSEncKey;
      act.sNwkSIntKey = act.nwkSEncKey;
    }

    DeviceStore.activate(act, resp => {
      this.props.history.push(`/organizations/${this.props.match.params.organizationID}/devices/${this.props.match.params.devEUI}`);
    });
  }
  setDeviceActivation(){
    if (this.props.match.params.devEUI === undefined) {
      return;
    }

    DeviceStore.getActivation(this.props.match.params.devEUI, resp => {
      if (resp === null) {
        this.setState({
          deviceActivation: null,
        });
      } else {
        this.setState({
          deviceActivation: resp.deviceActivation,
        });
      }
    });
  };
  render() {
    if (this.state.deviceActivation === undefined) {
      return null;
    }

    let submitLabel = null;
    if (!this.props.deviceProfile.supportsJoin) {
      submitLabel = "(Re)activate device";
    }

    let showForm = false;
    if (!this.props.deviceProfile.supportsJoin || (this.props.deviceProfile.supportsJoin && this.state.deviceActivation && this.state.deviceActivation.devAddr !== undefined)) {
      showForm = true;
    }

    return(
      <Card>
        <CardContent>
          {showForm && this.props.deviceProfile.macVersion.startsWith("1.0") && <LW10DeviceActivationForm
            submitLabel={submitLabel}
            object={this.state.deviceActivation}
            onSubmit={this.onSubmit}
            disabled={this.props.deviceProfile.supportsJoin}
            match={this.props.match}
            deviceProfile={this.props.deviceProfile}
          />}
          {showForm && this.props.deviceProfile.macVersion.startsWith("1.1") && <LW11DeviceActivationForm
            submitLabel={submitLabel}
            object={this.state.deviceActivation}
            onSubmit={this.onSubmit}
            disabled={this.props.deviceProfile.supportsJoin}
            match={this.props.match}
            deviceProfile={this.props.deviceProfile}
          />}
          {!showForm && <Typograhy variant="body1">
            This device has not (yet) been activated.
          </Typograhy>}
        </CardContent>
      </Card>
    );
  }
}

export default withRouter(DeviceActivation);
