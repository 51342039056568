import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from '@material-ui/core/Grid';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";


import moment from "moment";
import 'moment-duration-format';
import { Map, Marker } from 'react-leaflet';
import { Line} from "react-chartjs-2";

import MapTileLayer from "../../components/MapTileLayer";
import GatewayStore from "../../stores/GatewayStore";
import GatewayProfileStore from "../../stores/GatewayProfileStore.js";

import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import { ConnectionStatus } from "./ListGateways";
import TableCellLink from "../../components/TableCellLink";
import SettingsRemoteOutlinedIcon from '@material-ui/icons/SettingsRemoteOutlined';
import { checkGps } from '../../classes/Helper'
import { formatBytes } from "../../js/helpers/bytesConverter"
import HandyRustyStore from "../../stores/HandyRustyStore";
import _ from 'lodash'


const styles = {
  chart: {
    height: 300,
  },
  addInfoHeads: {
    fontSize: '1.5rem',
    marginBottom: '15px',
    display: 'block',
  },
  infoContainer: {
    marginRight: '50px'
  },
  infoCard: {
    display: 'flex',
  },
  gatewayIcon: {
    verticalAlign: 'bottom', cursor: 'pointer'
  },
  radioStatus: {
    paddingLeft: '30px',
  },
  link:{
    color:'unset'
  },
 
};

const matchLteLevel = (val) =>{
  if(!val){
    return ""
  }
  const map = {
    "2":[-109, "Marginal"],
    "3":[-107, "Marginal"],
    "4":[-105, "Marginal"],
    "5":[-103, "Marginal"],
    "6":[-101, "Marginal"],
    "7":[-99,	"Marginal"],
    "8":[	-97, "Marginal"],
    "9":[-95, "Marginal"],
    "10":[-93,	"OK"],
    "11":[	-91,	"OK"],
    "12":[	-89,	"OK"],
    "13":[	-87,	"OK"],
    "14":[-85,	"OK"],
    "15":[	-83, "Good"],
    "16":[-81,	"Good"],
    "17":[	-79,	"Good"],
    "18":[-77,	"Good"],
    "19":[-75,	"Good"],
    "20":	[-73,	"Excellent"],
    "21":	[-71,	"Excellent"],
    "22":[-69,	"Excellent"],
    "23":[-67,	"Excellent"],
    "24":	[-65,	"Excellent"],
    "25":	[-63,	"Excellent"],
    "26":[-61,	"Excellent"],
    "27":	[-59,	"Excellent"],
    "28":[-57,	"Excellent"],
    "29":	[-55,	"Excellent"],
    "30":[-53,	"Excellent"]
  }

  if(!map[val.toString()]){
    return ""
  }
    
  return {val:val,rssi:map[val.toString()][0],condition:map[val.toString()][1]}
  
}
const matchLteType = (val) =>{
  if (val===undefined){
    return ""
  }

  const map = {
    "0":"0 GSM",
    "1":"1 GSM Compact",
    "2":"2 UTRAN",
    "3":"3 GSM w/EGPRS[1]",
    "4":"4 UTRAN w/HSDPA[2]",
    "5":"4 UTRAN w/HSUPA[2]",
    "6":"4 UTRAN w/HSDPA[2] and HSUPA[2]",
    "7":"E-UTRAN",
  }

  if (!map[val.toString()]){
    return ""
  }
  let type = ""
  if(val>=0&&val<=1){
    type = "2G"
  }else if(val>1&&val<=6){
    type = "3G"
  }else{
    type = "4G" 
  }

  return {val:val, type:type}
}


const MetadataDisk = (props) =>{
 
let disks = {}
for (let i in props.metadata){
 let match = i.match(/disk/ig)
 
  if(match===null){
    continue
  }
  let num = i.match(/[0-9]/ig)

  if(num===null ){
    if(!disks[0]){
      disks[0] = {}
    }
    disks[0].usage = props.metadata[i]
    disks[0].path = ''
  }else{
    if(!disks[num[0]]){
      disks[num[0]] ={}
    }
    let name = i.match(/name/ig)
    if(name!==null){
      disks[num[0]].path = props.metadata[i]
    }else{
      disks[num[0]].usage = props.metadata[i]
    }
   
  }
}

  return (_.map(disks,(n)=>{
    return (
      <TableRow>
        <TableCell>Partition %: {n.path}</TableCell>
        <TableCell>{n.usage}</TableCell>
      </TableRow>
    )
  }))
}


class DetailsCard extends Component {

  render() {

    const StyledCardHeader = withStyles({
      content: {
        flex: 'unset',
      },
      action: {
        marginLeft: 'auto',
        display: 'flex'
      }
    })(CardHeader);

    return (
      <Card>
        <StyledCardHeader title="Gateway details" action={<>{this.props.outdated &&
        <div style={{color: 'red', marginRight: '20px'}}>GPS-OUTDATED</div>}<ConnectionStatus
          status={this.props.gateway.active}/></>}/>
        <CardContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell> {this.props.gateway.name} </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Gateway ID</TableCell>
                <TableCell>
                  {this.props.gateway.id.toUpperCase()}
                  <Link  style={styles.link} to={`/diagnostics?gw=${this.props.gateway.id}`}>
                  <SettingsRemoteOutlinedIcon titleAccess={'Show in Lorawan Logger'} style={styles.gatewayIcon}/></Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell> {this.props.gateway.address} </TableCell>
              </TableRow>
              {this.props.gateway.location && this.props.gateway.location.latitude !== 0 && this.props.gateway.location.longitude !== 0 &&
              <TableRow>
                <TableCell>GPS</TableCell>
                <TableCell style={{color: (this.props.outdated) ? 'red' : 'unset'}}><span
                  title={((this.props.GPSSeenAt) ? moment(this.props.GPSSeenAt).format("YYYY-MM-DD HH:mm:ss") : 'no timestamp')}>Lat: {checkGps(this.props.gateway.location.latitude)} Lon: {checkGps(this.props.gateway.location.longitude)} Alt: {this.props.gateway.location.altitude}</span>
                </TableCell>
              </TableRow>}
              <TableRow>
                <TableCell>Gateway description</TableCell>
                <TableCell>{this.props.gateway.description}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Serial number</TableCell>
                <TableCell>{this.props.gateway.serno}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>IMEI</TableCell>
                <TableCell> {this.props.gateway.metadata.lte_imei} </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mobile NO</TableCell>
                <TableCell>{this.props.gateway.phone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell> Profile Gateway </TableCell>
                {!this.props.gatewayProfile.id && <TableCell>{this.props.gateway.gatewayProfileID}</TableCell>}
                {this.props.gatewayProfile.id && <TableCellLink
                  to={`/gateway-profiles/${this.props.gatewayProfile.id}`}>{this.props.gatewayProfile.name}</TableCellLink>}
              </TableRow>
              <TableRow>
                <TableCell>Operational status</TableCell>
                <TableCell>{this.props.gateway.opStatusLabel}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Last seen at</TableCell>
                <TableCell>{this.props.lastSeenAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Created at</TableCell>
                <TableCell>{this.props.createdAt}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    )
  }
}

class GatewayDetails extends Component {
  constructor() {
    super();
    this.state = {
      gatewayProfile: {},
      radioStatus: {}
    };
    this.loadStats = this.loadStats.bind(this);
    this.getGwProfile = this.getGwProfile.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  componentDidMount() {
    this.loadStats();
    if (this.props.gateway.gatewayProfileID) {
      this.getGwProfile();
    }

    const id = this.props.gateway.id

    HandyRustyStore.getGwStats(id, (resp) => {
      this.setState({
        radioStatus: resp
      })
    })
  }

  goTo() {
    if (this.props.gateway.id) {
      this.props.history.push('/diagnostics?gw=' + this.props.gateway.id)
    }
  }

  getGwProfile() {
    GatewayProfileStore.get(this.props.gateway.gatewayProfileID, (obj) => {
      this.setState({gatewayProfile: obj.gatewayProfile})
    })
  }

  loadStats() {
    const end = moment().toISOString()
    const start = moment().subtract(30, "days").toISOString()

    GatewayStore.getStats(this.props.match.params.gatewayID, start, end, resp => {
      let statsDown = {
        labels: [],
        datasets: [
          {
            label: "rx received",
            borderColor: "rgba(33, 150, 243, 1)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            lineTension: 0,
            pointBackgroundColor: "rgba(33, 150, 243, 1)",
            data: [],
          },
        ],
      };

      let statsUp = {
        labels: [],
        datasets: [
          {
            label: "tx emitted",
            borderColor: "rgba(33, 150, 243, 1)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            lineTension: 0,
            pointBackgroundColor: "rgba(33, 150, 243, 1)",
            data: [],
          },
        ],
      };

      let statsDownError = {
        labels: [],
        datasets: [],
      };
      let statsDownErrorSets = {};

      let statsUpFreq = [];
      let statsDownFreq = [];
      let statsUpDr = [];
      let statsDownDr = [];

      for (const row of resp.result) {
        statsUp.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));
        statsUp.datasets[0].data.push(row.txPacketsEmitted);

        statsDown.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));
        statsDown.datasets[0].data.push(row.rxPacketsReceivedOK);

        statsDownError.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));
        Object.entries(row.txPacketsPerStatus).forEach(([k, v]) => {
          if (statsDownErrorSets[k] === undefined) {
            statsDownErrorSets[k] = [];
          }

          // fill gaps with 0s
          for (let i = statsDownErrorSets[k].length; i < statsDownError.labels.length - 1; i++) {
            statsDownErrorSets[k].push(0);
          }

          statsDownErrorSets[k].push(v);
        });

        statsUpFreq.push({
          x: moment(row.timestamp).format("YYYY-MM-DD"),
          y: row.rxPacketsPerFrequency,
        });

        statsDownFreq.push({
          x: moment(row.timestamp).format("YYYY-MM-DD"),
          y: row.txPacketsPerFrequency,
        });

        statsUpDr.push({
          x: moment(row.timestamp).format("YYYY-MM-DD"),
          y: row.rxPacketsPerDr,
        });

        statsDownDr.push({
          x: moment(row.timestamp).format("YYYY-MM-DD"),
          y: row.txPacketsPerDr,
        });
      }

      let backgroundColors = ['#8bc34a', '#ff5722', '#ff9800', '#ffc107', '#ffeb3b', '#cddc39', '#4caf50', '#009688', '#00bcd4', '#03a9f4', '#2196f3', '#3f51b5', '#673ab7', '#9c27b0', '#e91e63'];

      Object.entries(statsDownErrorSets).forEach(([k, v]) => {
        statsDownError.datasets.push({
          label: k,
          data: v,
          backgroundColor: backgroundColors.shift(),
        });
      });

      this.setState({
        statsUp: statsUp,
        statsDown: statsDown,
        statsUpFreq: statsUpFreq,
        statsDownFreq: statsDownFreq,
        statsUpDr: statsUpDr,
        statsDownDr: statsDownDr,
        statsDownError: statsDownError,
      });
    });
  }

  render() {
    if (this.props.gateway === undefined || this.state.statsDown === undefined || this.state.statsUp === undefined || this.state.statsUpFreq === undefined) {
      return(<div></div>);
    }

    const style = {
      height: 400,
    };

    const statsOptions = {
      animation: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
        x: {
          type: "time",
        },
      },
    };

    let position = [0, 0];
    if (this.props.gateway.location!== null && typeof (this.props.gateway.location.latitude) !== "undefined" && typeof (this.props.gateway.location.longitude !== "undefined")) {
      position = [this.props.gateway.location.latitude, this.props.gateway.location.longitude];
    }

    // GPS-OUTATED message trigger
    let mGPSSeenAt = (this.props.GPSSeenAt !== null) ? moment(this.props.GPSSeenAt):undefined;
    let outdated = this.props.gateway.active && this.state.gatewayProfile.gps && (!mGPSSeenAt || moment().diff(mGPSSeenAt, 'hours', true) > 3);

    let lastSeenAt = "Never";
    if (this.props.lastSeenAt !== null) {
      lastSeenAt = moment(this.props.lastSeenAt).format("YYYY-MM-DD HH:mm:ss");
    }

    let createdAt = undefined;
    if (this.props.createdAt !== null) {
      createdAt = moment(this.props.createdAt).format("YYYY-MM-DD HH:mm:ss");
    }

    let uptime = (Object.keys(this.props.gateway.metadata).length > 0) ? "unknown" : '';
    if (this.props.gateway.metadata.uptime !== undefined) {
      let duration = moment.duration(parseInt(this.props.gateway.metadata.uptime), 'seconds');
      uptime = duration.format("DD [days] hh:mm:ss");
    }

    let nsName = {name: this.props.gateway.networkServerName, id: this.props.gateway.networkServerID};

    const {
      eth_bytes_recv,
      eth_bytes_sent,
      eth_packets_recv,
      eth_packets_sent,
      eth_dropin,
      eth_dropout,
      eth_errin,
      eth_errout,
      eth_fifoin,
      eth_fifoout,
      vpn_packets_recv,
      vpn_packets_sent,
      vpn_bytes_recv,
      vpn_bytes_sent,
      vpn_dropin,
      vpn_dropout,
      vpn_errin,
      vpn_errout,
      vpn_fifoin,
      vpn_fifoout,
      wlan_packets_recv,
      wlan_packets_sent,
      wlan_bytes_recv,
      wlan_bytes_sent,
      wlan_dropin,
      wlan_dropout,
      wlan_errin,
      wlan_errout,
      wlan_fifoin,
      wlan_fifoout,
    } = this.props.gateway.metadata

    const {radioHour, radioDay, radioMonth} = this.state.radioStatus

    const bytesRx = eth_bytes_recv;
    const bytesTX = eth_bytes_sent;

    const bytesRxLte = vpn_bytes_recv;
    const bytesTXLte = vpn_bytes_sent;

    const bytesRxWlan = wlan_bytes_recv;
    const bytesTXWlan = wlan_bytes_sent;
    const lte_level = matchLteLevel(this.props.gateway.metadata.lte_level)
    const lte_type = matchLteType(this.props.gateway.metadata.lte_type)
    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <DetailsCard GPSSeenAt={this.props.GPSSeenAt}
                       outdated={outdated}
                       goTo={this.goTo}
                       createdAt={createdAt}
                       gateway={this.props.gateway}
                       gatewayProfile={this.state.gatewayProfile}
                       lastSeenAt={lastSeenAt}/>
        </Grid>
        <Grid item xs={6}>
          <Paper>
            <Map center={position}
                 zoom={15}
                 style={style}
                 animate={true}
                 scrollWheelZoom={false}>
              <MapTileLayer/>
              <Marker position={position}/>
            </Map>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Data from gateway"/>
            <CardContent className={this.props.classes.infoCard}>
              <div className={this.props.classes.infoContainer}>
                <span className={this.props.classes.addInfoHeads}>System</span>

                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>CPU %: </TableCell>
                      <TableCell> {this.props.gateway.metadata.cpu}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>RAM %: </TableCell>
                      <TableCell> {this.props.gateway.metadata.ram}</TableCell>
                    </TableRow>
                      <MetadataDisk metadata={this.props.gateway.metadata} /> 
                    <TableRow>
                      <TableCell>Uptime: </TableCell>
                      <TableCell>  {uptime} </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Gateway bridge version: </TableCell>
                      <TableCell>  {this.props.gateway.metadata.br_ver}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>FPGA version: </TableCell>
                      <TableCell> {this.props.gateway.metadata.fpga_ver}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Packet forwarder version: </TableCell>
                      <TableCell>{this.props.gateway.metadata.pf_ver} </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>NTP-client status: </TableCell>
                      <TableCell> {this.props.gateway.metadata.ntp_status}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div className={this.props.classes.infoContainer}>
                <span className={this.props.classes.addInfoHeads}>Interfaces</span>
                <Accordion square>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1d-content"
                    id="panel1d-header">
                    <Typography>Ethernet</Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>MAC: </TableCell>
                          <TableCell> {this.props.gateway.metadata.eth_mac}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>IP addresses: </TableCell>
                          <TableCell> {this.props.gateway.metadata.eth_ip}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Flags: </TableCell>
                          <TableCell>  {this.props.gateway.metadata.eth_flags} </TableCell>
                        </TableRow>
                        <TableRow>
                          {eth_packets_recv > 0 ? (<TableCell>RX</TableCell>) : ""}
                          {eth_packets_recv > 0 && (<TableCell>packets: {eth_packets_recv}</TableCell>)}
                        </TableRow>
                        {eth_bytes_recv > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>bytes: {eth_bytes_recv} ({formatBytes(bytesRx)})</TableCell>
                        </TableRow>)}
                        {eth_errin > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>errors: {eth_errin}</TableCell>
                        </TableRow>)}
                        {eth_dropin > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>droped: {eth_dropin}</TableCell>
                        </TableRow>)}
                        {eth_fifoin > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>FIFO: {eth_fifoin}</TableCell>
                        </TableRow>)}
                        <TableRow>
                          {eth_packets_sent > 0 ? (<TableCell>TX</TableCell>) : ""}
                          {eth_packets_sent > 0 ? (<TableCell>packets: {eth_packets_sent}</TableCell>) : ""}
                        </TableRow>
                        {eth_bytes_sent > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>bytes: {eth_bytes_sent} ({formatBytes(bytesTX)})</TableCell>
                        </TableRow>)}
                        {eth_errout > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>errors: {eth_errout}</TableCell>
                        </TableRow>)}
                        {eth_dropout > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>droped: {eth_dropout}</TableCell>
                        </TableRow>)}
                        {eth_fifoout > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>FIFO: {eth_fifoout}</TableCell>
                        </TableRow>)}
                      </TableBody>
                    </Table>

                  </AccordionDetails>
                </Accordion>
                <Accordion square>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1d-content"
                    id="panel1d-header">
                    <Typography>Wlan</Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>MAC: </TableCell>
                          <TableCell> {this.props.gateway.metadata.wlan_mac}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>IP addresses: </TableCell>
                          <TableCell> {this.props.gateway.metadata.wlan_ip}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Flags: </TableCell>
                          <TableCell>  {this.props.gateway.metadata.wlan_flags} </TableCell>
                        </TableRow>

                        <TableRow>
                          {wlan_packets_recv > 0 ? (<TableCell>RX</TableCell>) : ""}
                          {wlan_packets_recv > 0 && (<TableCell>packets: {wlan_packets_recv}</TableCell>)}
                        </TableRow>
                        {wlan_bytes_recv > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>bytes: {wlan_bytes_recv} ({formatBytes(bytesRxWlan)})</TableCell>
                        </TableRow>)}
                        {wlan_errin > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>errors: {wlan_errin}</TableCell>
                        </TableRow>)}
                        {wlan_dropin > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>droped: {wlan_dropin}</TableCell>
                        </TableRow>)}
                        {wlan_fifoin > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>FIFO: {wlan_fifoin}</TableCell>
                        </TableRow>)}
                        <TableRow>
                          {wlan_packets_sent > 0 ? (<TableCell>TX</TableCell>) : ""}
                          {wlan_packets_sent > 0 ? (<TableCell>packets: {wlan_packets_sent}</TableCell>) : ""}
                        </TableRow>
                        {wlan_bytes_sent > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>bytes: {wlan_bytes_sent} ({formatBytes(bytesTXWlan)})</TableCell>
                        </TableRow>)}
                        {wlan_errout > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>errors: {wlan_errout}</TableCell>
                        </TableRow>)}
                        {wlan_dropout > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>droped: {wlan_dropout}</TableCell>
                        </TableRow>)}
                        {wlan_fifoout > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>FIFO: {wlan_fifoout}</TableCell>
                        </TableRow>)}


                      </TableBody>
                    </Table>

                  </AccordionDetails>
                </Accordion>
                <Accordion square>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1d-content"
                    id="panel1d-header">
                    <Typography>Mobile</Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>IP addresses: </TableCell>
                          <TableCell> {this.props.gateway.metadata.vpn_ip}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Flags: </TableCell>
                          <TableCell>{this.props.gateway.metadata.vpn_flags}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>IMEI: </TableCell>
                          <TableCell>{this.props.gateway.metadata.lte_imei}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>IMSI: </TableCell>
                          <TableCell>{this.props.gateway.metadata.lte_imsi}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>ICCID: </TableCell>
                          <TableCell>{this.props.gateway.metadata.lte_iccid}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Signal level: </TableCell>
                          <TableCell> {lte_level.condition} {lte_level&&lte_level.val&&<>({lte_level.val})</>} </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Net type: </TableCell>
                          <TableCell> {lte_type.type} {lte_level&&lte_type.val&&<>({lte_type.val})</>} </TableCell>
                        </TableRow>
                        <TableRow>
                          {vpn_packets_recv > 0 ? (<TableCell>RX</TableCell>) : ""}
                          {vpn_packets_recv > 0 && (<TableCell>packets: {vpn_packets_recv}</TableCell>)}
                        </TableRow>
                        {vpn_bytes_recv > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>bytes: {vpn_bytes_recv} ({formatBytes(bytesRxLte)})</TableCell>
                        </TableRow>)}
                        {vpn_errin > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>errors: {vpn_errin}</TableCell>
                        </TableRow>)}
                        {vpn_dropin > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>droped: {vpn_dropin}</TableCell>
                        </TableRow>)}
                        {vpn_fifoin > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>FIFO: {vpn_fifoin}</TableCell>
                        </TableRow>)}
                        <TableRow>
                          {vpn_packets_sent > 0 ? (<TableCell>TX</TableCell>) : ""}
                          {vpn_packets_sent > 0 ? (<TableCell>packets: {vpn_packets_sent}</TableCell>) : ""}
                        </TableRow>
                        {vpn_bytes_sent > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>bytes: {vpn_bytes_sent} ({formatBytes(bytesTXLte)})</TableCell>
                        </TableRow>)}
                        {vpn_errout > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>errors: {vpn_errout}</TableCell>
                        </TableRow>)}
                        {vpn_dropout > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>droped: {vpn_dropout}</TableCell>
                        </TableRow>)}
                        {vpn_fifoout > 0 && (<TableRow>
                          <TableCell> </TableCell>
                          <TableCell>FIFO: {vpn_fifoout}</TableCell>
                        </TableRow>)}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>

                <Accordion square>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1d-content"
                    id="panel1d-header">
                    <Typography>VPN</Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>IP addresses: </TableCell>
                          <TableCell> </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Flags: </TableCell>
                          <TableCell> </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>

                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={this.props.classes.infoContainer}>
                <span className={this.props.classes.addInfoHeads}>LoRaWAN</span>

                <Table size="small">
                  <TableBody>

                    <TableRow>
                      <TableCell>Radio status: </TableCell>
                      <TableCell>{this.props.gateway.metadata.radio}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Frequency plan: </TableCell>
                      <TableCellLink to={`/network-servers/${nsName.id}`}>{nsName.name}</TableCellLink>
                    </TableRow>

                    <TableRow>
                      <TableCell>Percentage of Radio Availability:</TableCell>
                      <TableCell></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={this.props.classes.radioStatus}>Per hour %:</TableCell>
                      <TableCell>{radioHour&&((radioHour % 1 === 0) ? radioHour: radioHour.toFixed(2))}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={this.props.classes.radioStatus}>Per day %:</TableCell>
                      <TableCell>{radioDay&&((radioDay % 1 === 0) ? radioDay: radioDay.toFixed(2))}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={this.props.classes.radioStatus}>Per month %:</TableCell>
                      <TableCell>{radioMonth&&((radioMonth % 1 === 0) ? radioMonth: radioMonth.toFixed(2))}</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </div>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Received" />
            <CardContent className={this.props.classes.chart}>
              <Line height={75} options={statsOptions} data={this.state.statsDown} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Transmitted" />
            <CardContent className={this.props.classes.chart}>
              <Line height={75} options={statsOptions} data={this.state.statsUp} />
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={6}>
          <Card>
            <CardHeader title="Received / frequency" />
            <CardContent className={this.props.classes.chart}>
              <Heatmap data={this.state.statsUpFreq} fromColor="rgb(227, 242, 253)" toColor="rgb(33, 150, 243, 1)" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Transmitted / frequency" />
            <CardContent className={this.props.classes.chart}>
              <Heatmap data={this.state.statsDownFreq} fromColor="rgb(227, 242, 253)" toColor="rgb(33, 150, 243, 1)" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Received / DR" />
            <CardContent className={this.props.classes.chart}>
              <Heatmap data={this.state.statsUpDr} fromColor="rgb(227, 242, 253)" toColor="rgb(33, 150, 243, 1)" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Transmitted / DR" />
            <CardContent className={this.props.classes.chart}>
              <Heatmap data={this.state.statsDownDr} fromColor="rgb(227, 242, 253)" toColor="rgb(33, 150, 243, 1)" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Transmission / Ack status" />
            <CardContent className={this.props.classes.chart}>
              <Bar data={this.state.statsDownError} options={barOptions} />
            </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    );
  }
}


export default withStyles(styles)(GatewayDetails);
