import React , {Component} from 'react'
import { withStyles } from "@material-ui/core/styles";

import {Grid} from "@material-ui/core";



import SessionStore from '../../stores/SessionStore';

import ColumnSelector from './ColumnSelector'
import Fields from './Fields'
import Filter from './Filter'
import Table from './Table'

import history from '../../history'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

//import Map from './Map'
import L from "leaflet";
import { Map, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";
import MapTileLayer from "../../components/MapTileLayer";
import GatewayStore from '../../stores/GatewayStore'
import HandyRustyStore from "../../stores/HandyRustyStore";

import {  decodeFromHex} from "../../classes/Helper";
const styles = {

}

const defaultChecked = Fields.filter((el)=>{
  return el.default===true
}).map((el)=>{ 
    return el.dataName
})

class CttListLayout extends Component {
    constructor() {
        super();

        this.loadMap = this.loadMap.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClickClose = this.handleClickClose.bind(this);


        this.updateTable = this.updateTable.bind(this);
        this.resetTable = this.resetTable.bind(this);
      

        this.TableRef = React.createRef();
        this.FilterRef = React.createRef();

        this.onTabChange = this.onTabChange.bind(this);
        let storageFields = localStorage.getItem('CttFields')
        let parsed = (storageFields)?JSON.parse(storageFields):null
        let checked = defaultChecked
        if (parsed && parsed.length>0){
          checked = parsed
        }
        this.state = {
            csvData: "",
            exportTotal: 0,
            exportPerc: 0,
            tab: 0,
            fields: Fields.filter((field)=>(checked.indexOf(field.dataName)>-1)),
            checkedFields: checked,
            openFieldsDialog: false,
            filterChangeTimeOut: undefined,
            page: SessionStore.getPage(),
            gws:[],
            frames:[],
            filter: {
              "offset": 0,
              "limit": SessionStore.getRowsPerPage(),
            }
          };

  
    }
    loadMap(data){
      let that = this
      let filter = {}
      filter.filters = {}
      let query = SessionStore.getQueryString()
      
        if(filter['filters']===undefined){
          filter['filters'] = {}
        }
        filter['filters'].direction="RX"
        if(query.length===0){
          return 
        }
          if(query["devEUI"]){
            filter['filters']["devEUI"]  = query["devEUI"]
          
          }
          if(query['start']){
            filter['start'] = query['start']
          }
          if(query['end']){
            filter['end'] = query['end']
          }

          const fetchAllFrames  = () =>{
           
            return new Promise( (res ) =>{
              HandyRustyStore.getDeviceFrames(filter, 2000, 0,(obj)=>{
                
                res(obj.result  )
               } )
            })
          }

          const getAllGw = () =>{
            return new Promise( (res,rej)=>{
                GatewayStore.list(null,null,99999,0,(result)=>{
                    res(result.result)
                })
            } )
          }
        
          async function proccessData(){
                const allGateways = await getAllGw()
                const deviceFrames = await fetchAllFrames()
                const filteredGatewaysInFrames = [...new Set( deviceFrames.map(obj => obj.gw))]
              
                const filteredArray =  allGateways.filter( obj => filteredGatewaysInFrames.includes(obj.id))
                //that.setState({gws:filteredArray.map((el)=>{  return el.  })})
                that.setState({
                  gws:filteredArray,
                  frames:deviceFrames,
                  tab:1
                })
               
          }



          proccessData()
        
    }
    componentDidMount(){
      let that = this
      if (SessionStore.user === null ) {
        SessionStore.on("userGot", () => {
          const userStore = SessionStore.getUser();
          if (userStore === null || userStore === undefined) {
            window.sessionStorage.setItem('prevPage', '/ctt')
            history.replace('/login');
          }else {
            that.updateTable()
          }
        })
  
        if(!SessionStore.fetchingProfile && SessionStore.getToken() === null){
              SessionStore.fetchProfile(()=>{})
         }
      }
    }
    //selector dialog functions
    handleClickOpen() {
      this.setState({
        openFieldsDialog: true,
      });
    }
    handleClickClose(checkedFields) {
      let checked = (checkedFields.length>0)?checkedFields:defaultChecked;
  
      localStorage.setItem("CttFields", JSON.stringify(checked))
      this.setState({
        openFieldsDialog: false,
        checkedFields: checked,
        fields: Fields.filter((field) => (checked.indexOf(field.dataName) > -1)),
        limit: 'limit'
      });
    }
    ///
    handleFilterChange(data, timeout = 1500) {
      let filter = this.state.filter
  
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      let state = {filter: {...filter, ...data}}
      this.timeout = setTimeout(() => {
        let Obj = {}
        for(let i in state.filter){
          if(i==='offset'||i==='limit'){
              continue
          }
         
          if(i==='filters'){
            for(let f in state.filter[i]){
              if(state.filter[i][f]!==null&&state.filter[i][f]!==""&&state.filter[i][f]!==undefined){
                if(f==='direction'){
                  continue
                }
                Obj[f] = state.filter[i][f]
              }
             
            }
          }else{
           
              if(state.filter[i]!==""&&state.filter[i]!==null&&state.filter[i]!==undefined){
                  Obj[i] = state.filter[i] 
              }
          }
        }
  
        const u = new URLSearchParams(Obj).toString();
  
        this.props.history.replace('?'+u)
        this.setState(state,()=>{
          this.updateTable()
        })
        
 
      }, timeout)
    }
    onTabChange = (e, v) => {
      let state = {}
      state.tab = v
      if(v===1&&!this.state.frames.length){
        return
      }
      this.setState(state);
    }


      updateTable() {
        if (this.TableRef&&this.TableRef.current&&this.TableRef.current.updateTable) {
          this.TableRef.current.updateTable()
        }
      }
      resetTable() {
        if (this.TableRef&&this.TableRef.current&&this.TableRef.current.resetTable) {
          this.TableRef.current.resetTable()
        }
      }
    render() {
      let bounds = []
      let markers = [];
     

      const greenMarker = L.AwesomeMarkers.icon({
        icon: "wifi",
        prefix: "fa",
        markerColor: "green",
       
      });
  
      // const grayMarker = L.AwesomeMarkers.icon({
      //   icon: "wifi",
      //   prefix: "fa",
      //   markerColor: "gray",
      // });
  
      // const redMarker = L.AwesomeMarkers.icon({
      //   icon: "wifi",
      //   prefix: "fa",
      //   markerColor: "red",
      // });
  
      const orangeMarker = L.AwesomeMarkers.icon({
        icon: "wifi",
        prefix: "fa",
        markerColor: "orange",
      });
  
      for (const item of this.state.gws) { 
        if(item.location.latitude&&item.location.longitude){
          const position = [item.location.latitude , item.location.longitude ];
          const  marker = orangeMarker
          bounds.push(position);
          markers.push(
            <Marker position={position} key={`gw-${item.id}`} icon={marker}>
              <Popup>
                {/* <Link to={`/organizations/${item.organizationID}/gateways/${item.id}`}>{item.name}</Link><br /> */}
                {item.id}<br /><br />
             
              </Popup>
            </Marker>
          );
        }
      }

     
      for (const item of this.state.frames) { 
        let decoded = decodeFromHex(item.frmPayload,['nav'])
        let lat = parseFloat(decoded.latitude)
        let lon = parseFloat(decoded.longitude)
       if(lat&&lon){
          const position = [parseFloat(decoded.latitude)  ,parseFloat(decoded.longitude) ];

          bounds.push(position);
          const marker = greenMarker
          markers.push(
            <Marker position={position} icon={marker}>
              <Popup>
                {/* <Link to={`/organizations/${item.organizationID}/gateways/${item.id}`}>{item.name}</Link><br /> */}
                {item.devEUI}<br /><br />
              
              </Popup>
            </Marker>
          );
       }
      }
      const styleMap = {
        height: 600,
      };
      
        return (
          <Grid container
                spacing={4}
               >
          
            <Grid item xs={12} style={{paddingBottom: 0}}  id={"diagnostics"}>
              <Grid item style={{paddingBottom: 24}} >
                  <Tabs value={this.state.tab} onChange={this.onTabChange} indicatorColor="primary">
                    <Tab label="Logs"/>
                    <Tab label="Map"/>
                </Tabs>  
              </Grid>
           

              {this.state.tab===0&&<Grid item xs={12} >
                      <Filter ref={this.FilterRef}
                                            exportCSVPerc={this.state.exportPerc}
                                            clickOpenDialog={this.handleClickOpen}
                                        
                                            resetTable={this.resetTable}
                                            updateTable={this.updateTable}
                                          />
                                                
                    <Table 
                        loadMap={this.loadMap}
                        fields={this.state.fields}
                        limit={this.state.limit}
                        tableRef={this.TableRef}
                        disableAutoUpdate={this.disableAutoUpdate}
                        changePageEvent={this.changePageEvent}
                        page={this.state.page}
                        filter={this.state.filter}/>
              </Grid>}
              {this.state.tab===1&&<Map bounds={bounds} maxZoom={19} style={styleMap} animate={true} scrollWheelZoom={false}>
                  <MapTileLayer />
                  <MarkerClusterGroup>
                      {markers}
                  </MarkerClusterGroup>
                
                </Map>}
            </Grid>
         
            <ColumnSelector selectedFields={this.state.checkedFields}
                          open={this.state.openFieldsDialog}
                          onClose={this.handleClickClose}/>
          </Grid>
        );
      }
}

export default withStyles(styles)(CttListLayout);