import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import moment from "moment";
import PowerPlug from "mdi-material-ui/PowerPlug";
import TableCellLink from "../../components/TableCellLink";
import DataTable from "../../components/DataTable";
import DeviceStore from "../../stores/DeviceStore";
import theme from "../../theme";
import SettingsRemoteOutlinedIcon from '@material-ui/icons/SettingsRemoteOutlined';
import Check from "mdi-material-ui/Check";
import Close from "mdi-material-ui/Close";
import DevEUITextField from "../../components/DevEUITextField";
import { Box, IconButton, InputAdornment, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import ServiceProfileStore from "../../stores/ServiceProfileStore";
import RoutingProfileStore from "../../stores/RoutingProfileStore";
import {GetOptionSelected,GetFromQueryString} from '../../classes/Helper'

const styles = {
  buttons: {
    textAlign: "right",
  },
  button: {
    marginLeft: 2 * theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  importIcon: {fontSize: '20px', marginRight: '8px'},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  gatewayIcon: {
    verticalAlign: 'bottom', cursor: 'pointer'
  },
  selectSP: {
    minWidth: 300
  },
  flexCell: {
    display: 'flex',
    alignItems: 'center',
  }
};

class ListDevicesApp extends Component {
  constructor(props) {
    super(props);
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);

    this.changeSort = this.changeSort.bind(this);
    this.makeSortLabel = this.makeSortLabel.bind(this);
    this.makeCellSortProps = this.makeCellSortProps.bind(this);

    this.handleDevEUIChange = this.handleDevEUIChange.bind(this)
    this.handleDeviceNameChange = this.handleDeviceNameChange.bind(this)
    this.handleProfileChange =  this.handleProfileChange.bind(this)

    this.changeUrl = this.changeUrl.bind(this)
  

   this.getSPOptions=this.getSPOptions.bind(this)
   this.getRPOptions=this.getRPOptions.bind(this)
   this.TableRef = React.createRef()
    this.state = {
      orderBy: null,
      order: null,
      filter: {
        //showErrors: false,
        devEui: '',
        deviceName: '',
        sp: '',
        rp: '',
      },
    }
  }

  changeSort(event) {
    let state = {
      orderBy: event.currentTarget.dataset.key,
      order: this.state.order
    }

    if (state.orderBy !== this.state.orderBy) {
      state.order = null;
    }

    if (state.order === null) {
      state.order = 'asc'
    } else if (state.order === 'asc') {
      state.order = 'desc'
    } else if (state.order === 'desc') {
      state.order = null
      state.orderBy = null
    }

    this.setState(state,()=>{
      this.forceUpdate()
    });
  }

  makeSortLabel(keyName, viewName) {

    return <TableSortLabel
      data-key={keyName}
      active={this.state.orderBy === keyName}
      direction={this.state.orderBy === keyName ? this.state.order : 'asc'}
      onClick={this.changeSort}
    >
      {viewName}
      {this.state.orderBy === keyName ? (
        <span className={this.props.classes.visuallyHidden}>
                  {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
      ) : null}
    </TableSortLabel>
  }

  makeCellSortProps(keyName) {

    return {
      key: keyName,
      align: 'left',
      padding: 'normal',
      sortDirection: this.state.orderBy === keyName ? this.state.order : false
    }
  }

  getPage(limit, offset, callbackFunc) {
    let filter = {
   
      limit: limit,
      offset: offset,
      orderBy: this.state.orderBy,
      order: this.state.order
    }
    //filter.routingProfileId   filter.serviceProfileID  !!!
    let obj = GetFromQueryString(["rp","sp","devEui","deviceName"]);
    filter.routingProfileId = obj.rp;
    filter.serviceProfileID = obj.sp;
    filter.devEui           = obj.devEui;
    filter.deviceName       = obj.deviceName
    filter.organizationId = this.props.match.params.organizationID

    DeviceStore.list(filter, callbackFunc);
  }

  shouldComponentUpdate(nextProps,nextState,nextContext){
    return false;
  }
  componentDidMount(){

    let params = GetFromQueryString(["sp","rp","deviceName","devEui"],true)
    let state = this.state
    state.filter.sp = params.sp||this.state.filter.sp;
    state.filter.rp = params.rp||this.state.filter.rp;
    state.filter.deviceName = params.deviceName||this.state.filter.deviceName ;
    state.filter.devEui =   params.devEui||this.state.filter.devEui;

    this.setState(state)
  }
  getRow(obj) {
    let lastseen = "n/a";
    let battery = "n/a";
    // let packetCnt = obj.packetCnt ? obj.packetCnt : '';
    let rssiAvg = obj.RSSI ? Math.round(obj.RSSI) : '';
    let snrAvg = obj.SNR ? obj.SNR : '';
    let perAvg = obj.PER ? obj.PER : '';

    if (obj.SNR && (typeof(obj.SNR) == 'number' && !Number.isInteger(obj.SNR))) {
      snrAvg = `${snrAvg.toFixed(2)}`;
    }

    if (obj.PER && (typeof(obj.PER) == 'number' && !Number.isInteger(obj.PER))) {
      perAvg = `${Math.round(obj.PER)}`;
    }

    if (obj.lastSeenAt !== undefined && obj.lastSeenAt !== null) {
      lastseen = moment(obj.lastSeenAt).fromNow();
    }

    if (!obj.deviceStatusExternalPowerSource && !obj.deviceStatusBatteryLevelUnavailable) {
      battery = `${Math.round(obj.deviceStatusBatteryLevel)}%`
    }

    if (obj.deviceStatusExternalPowerSource) {
      battery = <PowerPlug/>;
    }

    // if (obj.deviceStatusMargin !== undefined && obj.deviceStatusMargin !== 256) {
    //   margin = `${obj.deviceStatusMargin} dB`;
    // }

    return (

      <TableRow
        key={obj.devEUI}
        hover
      >
        <TableCellLink
          to={`/organizations/${this.props.match.params.organizationID}/devices/${obj.devEUI}`}>
          {obj.name}
        </TableCellLink>
        <TableCell>
          <div className={this.props.classes.flexCell}>
            {obj.devEUI.toUpperCase()}
            <SettingsRemoteOutlinedIcon
              titleAccess={'Show in Lorawan Logger'}
              onClick={() => this.props.history.push('/diagnostics?devEUI=' + obj.devEUI)}
              style={styles.gatewayIcon}
            />
          </div>
        </TableCell>
        <TableCellLink to={`/device-profiles/${obj.deviceProfileID}`}>
          {obj.deviceProfileName}
        </TableCellLink>
        <TableCellLink
          to={`/organizations/${this.props.match.params.organizationID}/as-routing-profiles/${obj.routingProfileId}`}>
          {obj.routingProfileName}
        </TableCellLink>
        <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/service-profiles/${obj.serviceProfileId}`}>
          {obj.serviceProfileName}
        </TableCellLink>
        <TableCell>{lastseen}</TableCell>
        <TableCell>{battery}</TableCell>
        {/* <TableCell style={{width: '100px'}}>{packetCnt}</TableCell> */}
        <TableCell style={{width: '85px'}}>{perAvg}</TableCell>
        <TableCell style={{width: '85px'}}>{rssiAvg}</TableCell>
        <TableCell style={{width: '75px'}}>{snrAvg}</TableCell>
        <TableCell>{obj.isDisabled ? <Close/> : <Check/>}</TableCell>
      </TableRow>
    );
  }

  handleDevEUIChange = (e) => {
    let state = this.state;
    state.filter.devEui = e;
    this.setState(state,()=>{
      this.changeUrl()
      this.forceUpdate()
      this.TableRef.current.updateTable()
    });
  }

  handleDeviceNameChange = (e) => {
    let state = this.state;
    let val = e.target.value;
    state.filter.deviceName = val;
    this.setState(state,()=>{
      this.changeUrl()
      this.forceUpdate()
      this.TableRef.current.updateTable()
    });
  }
  handleProfileChange(e){
    const { id} = e.target
    let state = this.state;
    let val = e.target.value;
    state.filter[id] = val;
    this.setState(state,()=>{
      this.changeUrl()
      this.forceUpdate()
      this.TableRef.current.updateTable()
    });
  }
  changeUrl(){
    let Obj = {}
    for(let i in this.state.filter){
      if(this.state.filter[i]!==""&&this.state.filter[i]!==null&&this.state.filter[i]!==undefined){
        Obj[i] = this.state.filter[i] 
      }
    }

    const u = new URLSearchParams(Obj).toString();
    this.props.history.replace('?'+u)
  }
  clearSearch = () => {
    let state = this.state;
    state.filter.deviceName = '';
    this.setState(state,()=>{
      this.changeUrl()
      this.forceUpdate()
      this.TableRef.current.updateTable()
    });
  }


  getSPOption =(value,callbackFunc) =>{
    if(!value){return}
    this.getSPOptions(value,(arr)=>{
      for (let val in arr){
       if(arr[val].value===value){
        callbackFunc({label: arr[val].label, value: arr[val].value})
        return
       }
      }
      })
  }
  getSPOptions = (search, callbackFunc) => {
    ServiceProfileStore.list(this.props.match.params.organizationID, null, 999, 0, resp => {
      const options = resp.result.map((o, i) => {
        return {label: o.name, value: o.id}
      });
      callbackFunc([...options], undefined);
    });
  }

  getRPOptions(search, callbackFunc){
    RoutingProfileStore.list(search,999,0,this.props.match.params.organizationID, resp => {
      const options = resp.result.map((o, i) => {
        return {label: o.name, value: o.id}
      });
      callbackFunc([...options], undefined);
    });
   
  }
 
  render() {
  
   

    let params = GetFromQueryString(["sp","rp","deviceName","devEui"],true)

    let sp = params.sp||this.state.filter.sp;
    let rp = params.rp||this.state.filter.rp;
    let deviceName = params.deviceName||this.state.filter.deviceName ;
    let devEui =   params.devEui||this.state.filter.devEui;
    

    return (
      <>
        <Grid container
              spacing={4}
              justifyContent='flex-start'>
          <Grid item>
            <DevEUITextField
              value={devEui}
              handleDevEUIChange={this.handleDevEUIChange}
            />
          </Grid>
          <Grid item>
            <Box component="div" mr={2}>
              <TextField
                label={"DeviceName"}
                id="deviceName"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.handleDeviceNameChange}
                value={deviceName }
                InputProps={{
                  endAdornment:<>
                    {(deviceName)? <InputAdornment position="end">
                       <IconButton
                        onClick={this.clearSearch}
                        style={{padding: "0"}}
                        color="default"
                        component="span"
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>:null}</>,
                }}
                variant="outlined"
                size="small"
              />
            </Box>
          </Grid>

          <Grid item>
            <AutocompleteSelect
              id="sp"
              name="serviceProfileName"
              label="Select Service Profile"
              margin="none"
              onChange={this.handleProfileChange}
              value={sp}    
              getOptionSelected={GetOptionSelected}
              getOptions={this.getSPOptions}
              className={this.props.classes.selectSP}
              clearable={true}
            />
          </Grid>
          <Grid item>
            <AutocompleteSelect
              id="rp"
              name="routingProfileName"
              label="Select Routing Profile"
              margin="none"
              getOptionSelected={GetOptionSelected}
              onChange={this.handleProfileChange}
              value={rp}
              getOptions={this.getRPOptions}
              className={this.props.classes.selectSP}
              clearable={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} >
          <Grid item xs={12}>
            <DataTable
            orderBy={this.state.orderBy}
            ref={this.TableRef}
              header={
                <TableRow>
                  <TableCell {...this.makeCellSortProps('name')}>
                    {this.makeSortLabel('name', 'Device name')}
                  </TableCell>
                  <TableCell {...this.makeCellSortProps('devEUI')}>
                    {this.makeSortLabel('devEUI', 'Device EUI')}
                  </TableCell>
                  <TableCell {...this.makeCellSortProps('device-profile')}>
                    {this.makeSortLabel('device-profile', 'Device profile')}
                  </TableCell>
                  <TableCell {...this.makeCellSortProps('as-routing-profile')}>
                    {this.makeSortLabel('as-routing-profile', 'AS routing profile')}
                  </TableCell>
                  <TableCell {...this.makeCellSortProps('spName')}>
                    {this.makeSortLabel('spName', 'Service Profile')}
                  </TableCell>
                  <TableCell {...this.makeCellSortProps('lastSeen')}>
                    {this.makeSortLabel('lastSeen', 'Last seen')}
                  </TableCell>
                  <TableCell {...this.makeCellSortProps('battery')} >
                    {this.makeSortLabel('battery', 'Battery')}
                  </TableCell>
                  {/* <TableCell style={{width: '100px'}}>Messages</TableCell> */}
                  <TableCell {...this.makeCellSortProps('PER')} style={{width: '85px'}}>{this.makeSortLabel('PER', 'PER, %')}</TableCell>
                  <TableCell {...this.makeCellSortProps('RSSI')} style={{width: '85px'}}>{this.makeSortLabel('RSSI', 'Avg. RSSI, dBm')}</TableCell>
                  <TableCell {...this.makeCellSortProps('SNR')} style={{width: '75px'}}>{this.makeSortLabel('SNR', 'Avg. SNR, dB')}</TableCell>
                  <TableCell {...this.makeCellSortProps('isDisabled')}>
                    {this.makeSortLabel('isDisabled', 'Enable')}
                  </TableCell>
                </TableRow>
              }

              getPage={this.getPage}
              getRow={this.getRow}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
ListDevicesApp = withStyles(styles)(ListDevicesApp)

export default ListDevicesApp;
