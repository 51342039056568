import React from 'react';
import ImportExportIcon from "@material-ui/icons/ImportExport";
import {Dictionary} from "../../classes/Dictionary";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import Link from "@material-ui/core/Link";
import {CopyHelper} from '../../classes/Helper'
import moment from "moment";


const dict = new Dictionary('diagnostics-table-head');

function getGatewayById(id, rxInfo) {
  if (!id) return false;

  if (rxInfo) {
    let GW = rxInfo.find((item) => {
      return item.gateway_id === id;
    });
    return GW || false;
  }
}

function isInt(n){
  return Number(n) === n && n % 1 === 0;
}

function checkCoordLength(str) {
  let res = str.toString();
  if (res.length > 8) {
    let tmp = res.split('.');
    tmp[1] = tmp[1].slice(0, 5);
    res = tmp.join('.');
  }
  return res;
}

const colors = {
  green: '#18e314',
  orange: '#ffa500',
  red: '#ff0000'
}
const Fields = [
  {
    dataName: 'direction',
    head: {
      render: () => {
        return (<ImportExportIcon/>)
      },
      selector:()=>{
        return "Direction"
      }
    },
    cell: {
      render: (data) => {
        if (!data.direction){
          return <></>
        }
        const mapDirection = {
          "RX": {
            icon: PublishIcon,
            color: '#66BB6A'
          },
          "TX": {
            icon: GetAppIcon,
            color: '#FF5722'
          },
        }

        const IconMapped = (mapDirection[data.direction]) ? mapDirection[data.direction].icon : null
        return (
          <IconMapped htmlColor={mapDirection[data.direction].color || ''}/> || data.direction
        )
      }
    }
  },
  {
    dataName: 'payload',
    head: {
      render: () => {
        return ""
      },
      selector:()=>{
        return "Type"
      }
    },
    cell: {
      render: (data) => {

       let type = "";

       if(data.frmPayload&&data.macCommands){
          type = "M+D"
       }else if(data.frmPayload){
          type = "D"
       }else if (data.mType.match(/Join/g)){
        type = "J"
       }else if(data.macCommands){
        type = "M"
       }

      
        return type
      }
    }
  },
  {
    dataName: 'dateTime',
    head: {
      render: () => {
        return (dict.map('DateTime'))
      }
    },
    cell: {
      render: (data) => {
        return moment.utc(data.dateTime).format('YYYY-MM-DD HH:mm:ss.SSS');
      }
    }
  },
  {
    dataName: 'localTime',
    head: {
      render: () => {
        return (dict.map('LocalTime'))
      }
    },
    cell: {
      render: (data) => {
        return moment.utc(data.dateTime).local().format('YYYY-MM-DD HH:mm:ss.SSS')
      }
    }
  },
  {
    dataName: 'devAddr',
    head: {
      render: () => {
        return (dict.map('DevAddr'))
      }
    },
    cell: {
      render: (data) => {
        return (data.devAddr.toUpperCase())
      }
    },
    filter: {
      validation: {
        rule: /^[0-9a-fA-F]{8}$/ig   //hex only
      }
    }
  },
  {
    dataName: 'devEUI',
    head: {
      render: () => {
        return (dict.map('DevEUI'))
      }
    },
    cell: {
      render: (data) => {
        const copy = (e) =>{
            let devEui = e.target.innerText.match(/[0-9a-fA-F]{16}/ig)
            if(devEui){
              CopyHelper({target:{innerText:devEui[0]}})
            }
        }
        return (<span onDoubleClick={copy}>{data.devEUI.toUpperCase()} <Link href={`#/redirect?device=${data.devEUI}`}>&gt;&gt;</Link></span>)
      }
    },
    filter: {
      validation: {
        rule: /^[0-9a-fA-F]{16}$/ig   //hex only
      }
    }
  },
  {
    dataName: 'fPort',
    head: {
      render: () => {
        return (dict.map('FP'))
      }
    },
    cell: {
      render: (data) => {
        return (data.fPort)
      }
    }
  },
  {
    dataName: 'fCnt',
    head: {
      render: () => {
        return (dict.map('FCnt'))
      }
    },
    cell: {
      render: (data) => {
        if (data.direction === 'TX' && data.FCntUp === 0) {
          return ''
        }
        if (data.mType.match(/^Join/ig)) {
          return (data.FCntUp || '')
        } else {
          return (data.FCntUp)
        }
      }
    }
  },
  {
    dataName: 'nFCnt',
    head: {
      render: () => {
        return (dict.map('NFCnt'))
      }
    },
    cell: {
      render: (data) => {
        if (data.direction === 'TX' && data.NFCntDown === 0 && data.mType!=='JoinAccept') {
          return 0
        }
        return (data.NFCntDown || '')
      }
    }
  },
  {
    dataName: 'AFCnt',
    head: {
      render: () => {
        return (dict.map('AFCnt'))
      }
    },
    cell: {
      render: (data) => {
        return (data.AFCntDown || '')
      }
    }
  },
  {
    dataName: 'ConfFCnt',
    head: {
      render: () => {
        return (dict.map('ConfFCnt'))
      }
    },
    cell: {
      render: (data) => {
        return (data.ConfFCnt || '')
      }
    }
  },
  {
    dataName: 'rxRssi',
    head: {
      render: () => {
        return (dict.map('RxRssi'))
      }
    },
    cell: {
      render: (data) => {
        return ((data.direction === 'TX' && data.rxRssi === 0) ? '' : data.rxRssi)
      }
    }
  },
  {
    dataName: 'rxSnr',
    head: {
      render: () => {
        return (dict.map('RxSnr'))
      }
    },
    cell: {
      style: (data) => {

        let color = ''

        if (data.rxSnr >= -8) {
          color = (data.rxSnr === 0 && data.direction === 'TX') ? '' : colors.green
        } else if (data.rxSnr >= -13 && data.rxSnr < -8) {
          color = colors.orange
        } else {
          color = colors.red
        }

        if (color) {
          return {
            background: color
          }
        }
      },
      render: (data) => {
      


        return ((data.rxSnr === 0 && data.direction === 'TX') ? '' : (isInt(data.rxSnr)?data.rxSnr:data.rxSnr.toFixed(1) ))
      }
    }
  },
  {
    dataName: 'esp',
    head: {
      render: () => {
        return (dict.map('Esp'))
      }
    },
    cell: {
      style: (data) => {

        let color = ''

        if (data.esp >= -100) {
          color = (data.esp === 0 && data.direction === 'TX') ? '' : colors.green
        } else if (data.esp >= -110 && data.esp < -100) {
          color = colors.orange
        } else {
          color = colors.red
        }

        if (color) {
          return {
            background: color
          }
        }
      },
      render: (data) => { 
        if(typeof data.esp === 'string'){
          let sep = data.esp.split('.')
          data.esp = sep[0]
        }

        return ((data.esp === 0 && data.direction === 'TX') ? '' : data.esp)
      }
    }
  },
  {
    dataName: 'SF',
    head: {
      render: () => {
        return (dict.map('SF'))
      }
    },
    cell: {
      style: (data) => {
        if (data && data.txInfo && data.txInfo.ModulationInfo.LoraModulationInfo && data.txInfo.ModulationInfo.LoraModulationInfo && data.txInfo.ModulationInfo.LoraModulationInfo.spreading_factor) {
          let sf = data.txInfo.ModulationInfo.LoraModulationInfo.spreading_factor;

          let color = ''

          if (sf <= 10) {
            color = colors.green
          } else if (sf === 11) {
            color = colors.orange
          } else if (sf === 12) {
            color = colors.red
          }

          if (color) {
            return {
              background: color
            }
          }
        }
      },
      render: (data) => {
        if (data && data.txInfo && data.txInfo.ModulationInfo.LoraModulationInfo && data.txInfo.ModulationInfo.LoraModulationInfo && data.txInfo.ModulationInfo.LoraModulationInfo.spreading_factor) {
          return data.txInfo.ModulationInfo.LoraModulationInfo.spreading_factor;
        }
        return (null)
      }
    }
  },
  {
    dataName: 'channel',
    head: {
      render: () => {
        return (dict.map('Ch'))
      }
    },
    cell: {
      render: (data) => {
        const gw = getGatewayById(data.gw, data.RxInfo)
        if (!!gw) {
          return gw.channel
        }
        return (null)
      }
    }
  },
  {
    dataName: 'gw',
    head: {
      render: () => {
        return (dict.map('GW Id'))
      }
    },
    cell: {
      render: (data) => {
        const copy = (e) =>{
          let gw = e.target.innerText.match(/[0-9a-fA-F]{16}/ig)
          if(gw){
            CopyHelper({target:{innerText:gw[0]}})
          }
      } 
       return (<span onDoubleClick={copy}>{data.gw.toUpperCase()} <Link href={`#/redirect?gateway=${data.gw}`}>&gt;&gt;</Link></span>)
      }
    },
    filter: {
      validation: {
        rule: /^[0-9a-fA-F]{16}$/ig   //hex only
      }
    }
  },
  {
    dataName: 'GW Lat',
    head: {
      render: () => {
        return (dict.map('GW Lat'))
      }
    },
    cell: {
      render: (data) => {
        const gw = getGatewayById(data.gw, data.RxInfo)
        if (!!gw && gw.location !== null && Object.keys(gw.location).length > 0) {
          return checkCoordLength(gw.location.latitude);
        }
        return (null)
      }
    }
  },
  {
    dataName: 'GW Long',
    head: {
      render: () => {
        return (dict.map('GW Long'))
      }
    },
    cell: {
      render: (data) => {
        const gw = getGatewayById(data.gw, data.RxInfo)
        if (!!gw && gw.location !== null && Object.keys(gw.location).length > 0) {
          return checkCoordLength(gw.location.longitude);
        }
        return (null)
      }
    }
  },
  {
    dataName: 'GW GWCnt',
    head: {
      render: () => {
        return (dict.map('GWs'))
      }
    },
    cell: {
      render: (data) => {
        if (data.RxInfo && data.RxInfo.length > 0) {
          return data.RxInfo.length
        } else {
          if (data.gw && data.gw !== '' && data.direction !== 'TX') {
            return 1
          } else {
            return ''
          }
        }
      }
    }
  },
  {
    dataName: 'mic',
    head: {
      render: () => {
        return (dict.map('Mic'))
      }
    },
    cell: {
      render: (data) => {
        return (data.mic)
      }
    }
  },
    {
        dataName: 'limit',
        head: {
            render: () => {
                return (dict.map('Limit'))
            }
        },
        cell: {
            render: (data) => {
                if (data.limit === "D") {
                    return "DROP"
                } else if (data.limit === "M") {
                    return "MARK"
                } else {
                    return ''
                }
            }
        }
    },
  {
    dataName: 'mType',
    head: {
      render: () => {
        return (dict.map('MType'))
      }
    },
    cell: {
      render: (data) => {
        return (data.mType)
      }
    }
  },
  {
    dataName: 'late',
    head: {
      render: () => {
        return (dict.map('Late'))
      }
    },
    cell: {
      render: (data) => {
        return (data.late)
      }
    }
  },
  {
    dataName: 'Sub Band',
    head: {
      render: () => {
        return (dict.map('Sub Band'))
      }
    },
    cell: {
      render: (data) => {
        return (null)
      }
    }
  },

  {
    dataName: 'nS Id',
    head: {
      render: () => {
        return (dict.map('NS Id'))
      }
    },
    cell: {
      render: (data) => {
        return (null)
      }
    }
  },
  {
    dataName: 'device Lat',
    head: {
      render: () => {
        return (dict.map('Device Lat'))
      }
    },
    cell: {
      render: (data) => {
        return (null)
      }
    }
  },
  {
    dataName: 'device Lon',
    head: {
      render: () => {
        return (dict.map('Device Lon'))
      }
    },
    cell: {
      render: (data) => {
        return (null)
      }
    }
  },
  {
    dataName: 'loS Distance (m)',
    head: {
      render: () => {
        return (dict.map('LoS Distance (m)'))
      }
    },
    cell: {
      render: (data) => {
        return (null)
      }
    }
  },
  {
    dataName: 'Map',
    head: {
      render: () => {
        return (dict.map('Map'))
      }
    },
    cell: {
      render: (data) => {
        return (null)
      }
    }
  },
  {
    dataName: 'trip',
    head: {
      render: () => {
        return (dict.map('Trip'))
      }
    },
    cell: {
      render: (data) => {
        return (null)
      }
    }
  },
];


export default Fields
