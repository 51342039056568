import React from "react";
import {withStyles} from '@material-ui/core/styles';
import FormLabel from "@material-ui/core/FormLabel";
import TextField from '@material-ui/core/TextField';
import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import HandyRustyStore from "../../stores/HandyRustyStore"
import theme from "../../theme";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DataTable from "../../components/DataTable";
import TransferList from "./TransferList";
import {withRouter} from "react-router-dom";
import moment from "moment";
import { Fragment } from "react";
import { FormControlLabel, IconButton, Radio, RadioGroup } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import { getUuid } from "../../classes/Helper";
import DevEUITextField from "../../components/DevEUITextField";

const styles = {
  root: {
    marginBottom: 20,
    borderTop: "1px solid white",
  },
  root2: {
    marginBottom: 10,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  flexRow: {
    display: 'flex',
  },
  radioRow: {
    flexDirection: 'row',
    marginRight: '30px',
    marginLeft: '30px'
  },
  deveuiField: {
    marginRight: '30px',
    width: '300px'
  },
  a: {
    color: theme.palette.primary.main,
  },
  formLabel: {
    marginTop: 20,
    marginBottom: 20,
  },
};

class AsRoutingForm extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      description: '',
      name: '',
      filter: {
        showErrors: '',
        devEUI: ''
      }
    }

    this.tableRef = React.createRef();

    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.toggleFilterErrorChange = this.toggleFilterErrorChange.bind(this);
    this.handleDevEUIChange = this.handleDevEUIChange.bind(this);
    this.handleRefreshTable = this.handleRefreshTable.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.props.object !== undefined) {
      const { object} = this.props;

      this.setState({
        object: object || {},
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.object !== this.props.object) {

      const { object} = this.props;

      this.setState(
        { object: object || {} }
      )}
  }

  onChangeValue = (e) => {
    const {name, value} = e.target;
    let object = this.state.object;
    object[name] = e.target.value;

    this.setState({
      [name]: value,
      object: object,
    })
  }

  handleUpdateServerList = (arr) => {

    this.setState(prevState => ({
      object: {
        ...prevState.object,
        applications: arr,
      }
    }))
  }

  getPage(limit, offset, callbackFunc) {
    let filter = {devEui: this.state.filter.devEUI};
    if(this.state.filter.showErrors===true){
      filter.isError = true
    }else if (this.state.filter.showErrors===false){
      filter.isOk = true
    }
    HandyRustyStore.getIntegrationReplies(filter, limit, offset, this.props.match.params.organizationID, this.state.object.id, "", "", callbackFunc);
  }

  getRow(obj) {
    let logTime = '';
    if (obj.time) {
      logTime = moment(obj.time).format("YYYY-MM-DD HH:mm:ss");
    }

    return(
      <TableRow
        key={getUuid()}
        hover
      >
        <TableCell>{logTime}</TableCell>
        <TableCell>{obj.devEUI.toUpperCase()}</TableCell>
        <TableCell>{obj.statusCode} {obj.statusCodeDescription}</TableCell>
        <TableCell>{obj.integrationUrl}</TableCell>
      </TableRow>
    );
  }

  toggleFilterErrorChange(e) {
    let filter = this.state.filter
    if(e.target.value!==''){
      filter.showErrors = JSON.parse(e.target.value);
    }else{
      filter.showErrors = ''
    }
      
    
    this.setState({
      filter
    });
  }

  handleDevEUIChange(e) {
    let state = this.state;
    state.filter.devEUI = e;
    this.setState(state);
  }

  handleRefreshTable() {
    if (this.tableRef.current.updateTable) {
      this.tableRef.current.resetTable();
    }
  }

  render() {

    if (this.state.object === undefined) {
      return(<div></div>);
    }

    const {tab, appServers, submitLabel} = this.props;
    const {name, description} = this.state;
    let object = this.state.object;

    return(
      <Form
        submitLabel={tab === 0 ? submitLabel : ""}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >

        {tab === 0 && (
          <>
            <TextField
              id="name"
              label="AS routing profile name"
              margin="normal"
              name="name"
              value={name || object.name}
              onChange={this.onChangeValue}
              required
              fullWidth
              disabled={this.props.disabled}
            />
            <TextField
              id="descriptionTop"
              label="Description"
              name="description"
              margin="normal"
              value={description || object.description}
              onChange={this.onChangeValue}
              rows={1}
              multiline
              fullWidth
              disabled={this.props.disabled}
            />
            <div className={this.props.classes.root}></div>
            <FormLabel className={this.props.classes.root2}>Select Application servers</FormLabel>
            <TransferList
              handleUpdateServerList={this.handleUpdateServerList}
              appServers={appServers}
              selectedApplicationIds={this.state.object.applications ? this.state.object.applications : []}
              disabled={this.props.disabled}
            />

          </>
        )}

        {tab === 1 && (
          <Fragment>
            <div className={this.props.classes.flexRow}>
              <DevEUITextField
                value={this.state.filter.devEUI}
                handleDevEUIChange={this.handleDevEUIChange}
              />

              <RadioGroup
                name="errors"
                onChange={this.toggleFilterErrorChange}
                value={this.state.filter.showErrors}
                className={this.props.classes.radioRow}
              >
                <FormControlLabel
                  value={''}
                  control={<Radio color="primary" />}
                  label="All"
                />
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary"/>}
                  label="Errors"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary"/>}
                  label="200 OK"
                />
              </RadioGroup>

              <IconButton
                title={'refresh data'}
                style={{marginRight:'10px'}}
                size="small"
                variant="text"
                onClick={this.handleRefreshTable}
              >
                <RefreshIcon />
              </IconButton>
            </div>
            <DataTable elevation={0}
              header={
                <TableRow>
                  <TableCell>Created at</TableCell>
                  <TableCell>DevEUI</TableCell>
                  <TableCell>HTTP status</TableCell>
                  <TableCell>Endpoint</TableCell>
                </TableRow>
              }
              getPage={this.getPage}
              getRow={this.getRow}
              ref={this.tableRef}
            />
          </Fragment>
          
        )}
      </Form>
    );
  }
};

export default withRouter(withStyles(styles)(AsRoutingForm));
