import React, { Component } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { enUS } from 'date-fns/locale'
import { Popover, TextField, InputAdornment } from '@material-ui/core';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  DateTimePicker
} from '@material-ui/pickers';
import CalendarIcon from '@material-ui/icons/DateRangeSharp';
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import moment from 'moment';

let styles = {
    pickerPopover:{
        display:'flex',
        flexDirection:'column',
        padding:'10px'
    },

}


class CustomDatePicker extends Component {
  constructor(props) {
    super(props);

    this.pickerOpen = this.pickerOpen.bind(this)
    this.pickerClose = this.pickerClose.bind(this)
    this.clear = this.clear.bind(this)
    this.handleChangeType = this.handleChangeType.bind(this)
    this.handleDateTimeChange = this.handleDateTimeChange.bind(this)



    this.state = {
      anchorPicker:null,
      selectedOption: undefined,
      start_val: null,
      end_val: null,
    };
  }
  pickerOpen(e){
   
    document.activeElement.blur();
    this.setState({anchorPicker:e.currentTarget})
  }
  pickerClose(){
    let state = {
      anchorPicker:null
    }
  
    let start = '';
    let end = '';
    let update = true;

    switch(this.state.selectedOption){
      case 'Day': 
        start = moment().subtract(1,'days');
        state.date = "Last day";
        break;

      case 'Month': 
        start = moment().subtract(1,'months');
        state.date = "Last month";
        break;

      case 'Interval':
        if(this.state.start_val){
          start = moment(this.state.start_val)
        }

        if(this.state.end_val){
          end = moment(this.state.end_val)
        }

        state.date = moment(start).format('D.MM.YY') + "-" + moment(end).format('D.MM.YY')

        if (!start || !end) { 
          update=false;
          state.selectedOption = undefined 
          state.date =""
        }
        break;
        
        default:
          break;
    }

    this.setState(state)

    if(update){

      if (start !== '') {
        start = start.toISOString()
      }
      if (end !== '') {
        end = end.toISOString()
      }

      this.props.handleChange({
        start: start,
        end: end,
      })
    }
    


  }
  clear(e){
    if(e.isPropagationStopped&&!e.isPropagationStopped()){
      e.stopPropagation()
    }
    this.setState({start_val:null,end_val:null,selectedOption:undefined,date:""})
    this.props.handleChange({
      start: "",
      end: "",
    })
   
  }

  handleChangeType = (event) => {
    let state = this.state

    state.selectedOption =  event.target.value
    if( event.target.value === "Interval" ){
      state.start_val = null;
      state.end_val = null;
    }

    this.setState(state);
  };

  handleDateTimeChange = (all, type) => {
    this.setState({ [`${type}_val`]: all[0] });
  };

  componentDidMount(){
    if(this.props.filter && (this.props.filter.start || this.props.filter.end)) {
      let state = this.state;
  
      state.selectedOption = "Interval";

      state.date = "...";
      if (this.props.filter.start !== "") {
        state.start_val = this.props.filter.start;
        state.date = moment( this.props.filter.start ).format('D.MM.YY');
      }

      if (this.props.filter.end !== "") {
        state.end_val =  this.props.filter.end;
        state.date = state.date + "-" + moment( this.props.filter.end ).format('D.MM.YY');
      }      
  
      this.setState(state)
    }
  }


  render() {
    let picker;
   if (this.state.selectedOption==='Interval') {
    picker = (
      
      <React.Fragment>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enUS}>
          <DateTimePicker
           autoOk
           ampm={false}
           InputProps={{
            endAdornment: (
              <IconButton>
                <CalendarIcon />
              </IconButton>
            ),
          }}
           variant="inline"
           label="Day start"
           format="dd MMM yyyy HH:mm"
           value={this.state.start_val}
           onChange={(...all) => this.handleDateTimeChange(all, 'start')}
           onError={console.log}
            style={{marginBottom:'10px'}}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enUS}>
          <DateTimePicker
          InputProps={{
            endAdornment: (
              <IconButton>
                <CalendarIcon />
              </IconButton>
            ),
          }}
            autoOk
            ampm={false}
            variant="inline"
            label="Day end"
            value={this.state.end_val}
            onChange={(...all) => this.handleDateTimeChange(all, 'end')}
            onError={console.log}
            format="dd MMM yyyy  HH:mm"
          />
        </MuiPickersUtilsProvider>
      </React.Fragment>
     )
    }
    const open = Boolean(this.state.anchorPicker);
    const id = open ? 'simple-popover' : undefined;
    
    return (
     <div>
      
     <TextField
              onMouseDown={this.pickerOpen}
              label={ "Date"}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">

                    <IconButton
                    onMouseDown={(e)=>{
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                     onMouseUp={this.clear}
                      style={{padding: "0"}}
                      color="default"
                      aria-label="upload picture"
                      component="span">
                      <Icon className="fa fa-times"
                      />
                    </IconButton>
                  </InputAdornment>,
              }}
              variant="outlined"
              size="small"
              
              value={this.state.date || ""}
            />
             
          
        <Popover
            id={id}
            open={open}
            anchorEl={this.state.anchorPicker}
            onClose={this.pickerClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            
        >
        <div className={this.props.classes.pickerPopover}>  
                <FormControl component="fieldset" className={this.props.classes.pickerSelector}>
                        <RadioGroup aria-label="time-option" name="time-option" value={this.state.selectedOption} onChange={this.handleChangeType}>
                            <FormControlLabel value="Day" control={<Radio />} label="Last day" />
                            <FormControlLabel value="Month" control={<Radio />} label="Last month" />
                            <FormControlLabel value="Interval" control={<Radio />} label="Interval" />
                        </RadioGroup>
                    </FormControl>
                    {picker}
        </div>
            
          
        </Popover>
     
     </div>   
     
    );
  }
}

export default  withStyles(styles)(CustomDatePicker);