import React from "react";

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from '@material-ui/core/Checkbox';

import FormComponent from "../../classes/FormComponent";
import FormControl from "../../components/FormControl";
import Form from "../../components/Form";
import SessionStore from "../../stores/SessionStore";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from '@material-ui/core/InputAdornment';
import InputMask  from "react-input-mask";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Refresh from "mdi-material-ui/Refresh";
import ClipboardTextMultipleOutline from "mdi-material-ui/ClipboardTextMultipleOutline";
import generator from 'password-generator'
import {CopyHelper} from '../../classes/Helper'
import { withStyles } from "@material-ui/core";
import Admin from "../../components/Admin";
import {isStrongEnough} from './ChangeUserPassword'
import VisibilityIcon from '@material-ui/icons/Visibility';

// let UPPERCASE_RE = /([A-Z])/g;
// let NUMBER_RE = /([\d])/g;
// let SPECIAL_CHAR_RE = /([?-])/g;
// let NON_REPEATING_CHAR_RE = /([\w\d`!@#$%^&*()_+\-=[\]{};':"\\|<>/?~])\1{2,}/g;
// let uppercaseMinCount = 2;
// let numberMinCount = 2;
let length = 13;
// let specialMaxCount = 1;
// const isStrongEnough = (password)=>{
 
//   let uc = password.match(UPPERCASE_RE);
//   let n = password.match(NUMBER_RE);
//   let sc = password.match(SPECIAL_CHAR_RE);
//   let nr = password.match(NON_REPEATING_CHAR_RE);

   
//   return !nr  
//         && uc && uc.length >= uppercaseMinCount  
//         && n && n.length >= numberMinCount 
//         && sc && sc.length <= specialMaxCount; 
// }
class UserForm extends FormComponent {
  constructor(){
    super();
    this.generatePassword = this.generatePassword.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.onChangeNote = this.onChangeNote.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangePass = this.onChangePass.bind(this);
    this.initPassClick = this.initPassClick.bind(this);
    this.changeType = this.changeType.bind(this);
  }

  generatePassword () {
    this.setState({newPassClicked:true})

    const customPassword = () => {
      let password = "";
      while (!isStrongEnough(password)) {
        password = generator(length, false , /[\w\d?-]/);
      }
      return password;
    }
    let pswd = customPassword();
    CopyHelper({
      target:{innerText:pswd}
    });
    this.onChange({
      target: {
        id: 'password',
        value: pswd,
      }
    })
  }
  initPassClick(){
    this.setState({newPassClicked:true})
  }
  copyToClipboard() {
    if(!this.state.object.password){
      this.generatePassword()
    }
    CopyHelper({
      target:{innerText: this.state.object.password}
    });
  }

  onChangeNote(e) {
    if (e.target.value.length <= 300) {
      this.onChange(e);
    }
  }
  onChangePass(e){
    let event = {
      target:{
        id:e.target.id,
        value:e.target.value,
        type:"text"
      }
    }
    if(!this.state.newPassClicked){
      this.setState({newPassClicked:true},()=>{
        this.onChange(event);
      })
    }else{
      this.onChange(event);
    }
  
  }
  changeType(){
    this.setState({
      passOpen : !this.state.passOpen
    })
  }
  onChangePhone(e) {
    
    if(e.target.value||e.target.value===""){
      let match = e.target.value.match(/^[/+]{1}[0-9]+$/g) 
      if(match||e.target.value===""){
        e.target.value = (match)? match[0]: "" 
        this.onChange(e);
      }
      
    }
    
  }

  render() {
    if (this.state.object === undefined) {
      return(<div></div>);
    }


    let passwordError = false
   
    if(this.state.object.password&&this.state.object.password.length>0  ){
      passwordError =  !isStrongEnough(this.state.object.password)
    }
    let passType = (this.state.newPassClicked)?"password":"text"
    if(this.state.passOpen){
      passType = "text"
    }else{
      passType = "password"
    }
  
    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={(e)=>{
          if(passwordError){
            e.preventDefault()
            return
          }
          this.onSubmit(e)
        }}
      >
         <TextField
          id="name"
          label="First name"
          margin="normal"
          value={this.state.object.name || ""}
          onChange={this.onChange}
          required
          fullWidth
        />
         <TextField
          id="surname"
          label="Last name"
          margin="normal"
          value={this.state.object.surname || ""}
          onChange={this.onChange}
          required
          fullWidth
        />
        <TextField
          id="email"
          label="E-mail address"
          margin="normal"
          type="email"
          value={this.state.object.email || ""}
          onChange={this.onChange}
          required
          fullWidth
        />
        <TextField
          id="company"
          label="Company"
          margin="normal"
          
          value={this.state.object.company || ""}
          onChange={this.onChange}
          required
          fullWidth
        />
        <TextField
          id="position"
          label="Position/Subdivision"
          margin="normal"
          value={this.state.object.position || ""}
          onChange={this.onChange}
          fullWidth
        />
              <InputMask
            mask="?999999999999999"
            formatChars={{"9": "[0-9]", "?": "[0-9+]"}} 
            value={this.state.object.phone || ""}
            onChange={this.onChange}
            disabled={false}
            maskChar={null}
          >
            {() =><TextField
            
            id="phone"
            label="Phone Number"
            margin="normal"
           // value={this.state.object.phone || ""}
          //  onChange={this.onChangePhone}
            fullWidth
            type="text"
          />}
          </InputMask>
          
      
        <TextField
          id="note"
          label="Optional note"
          helperText="Optional note, e.g. a phone number, address, comment..."
          margin="normal"
          value={this.state.object.note || ""}
          onChange={this.onChangeNote}
          rows={4}
          fullWidth
          multiline
        />
        {SessionStore.isAdmin() && ((this.state.sessionTTLClicked||(this.state.object.sessionTTL!==undefined && this.state.object.sessionTTL!==0 && this.state.object.sessionTTL!=='' && this.state.object.sessionTTL!==null) )?<TextField
            id="sessionTTL"
            label="User session TTL (in minutes)"
            helperText="If zero - session time to live will be set to default value"
            margin="normal"
            type="number"
            value={this.state.object.sessionTTL  }
            onBlur={(e)=>{
        
                if(this.state.object.sessionTTL===undefined||isNaN( this.state.object.sessionTTL)|| this.state.object.sessionTTL==='' ){
                      this.setState({sessionTTLClicked:false})
                }
            }}
            onChange={(e)=>{ 
             
                this.onChange(e)
              }}
            fullWidth
            required
        /> :<TextField
        id="sessionTTL"
        label="User session TTL (in minutes)"
        helperText="If zero - session time to live will be set to default value"
        margin="normal"
        type="text"
        value={"Default" }
        onMouseUp={(e)=>{
          this.setState({sessionTTLClicked:true})
        }}
        onClick={(e)=>{
          this.setState({sessionTTLClicked:true})
        }}
        fullWidth
        required
    /> ) }
         
          <TextField
            label="Last Login:"
            margin="normal"
            type="text"
            value={(this.props.dates)?this.props.dates.loginAt:null }
            disabled
            fullWidth
        />
        <TextField
            label="Created At:"
            margin="normal"
            type="text"
            value={(this.props.dates)?this.props.dates.createdAt:null}
            disabled
            fullWidth
        />
        
        {this.state.object&&this.state.object.id === undefined && this.state.object.userID === undefined &&  <TextField
          id="password"
          label="Password"
          type={passType}
          margin="normal"
          helperText={`Must have at least 2 uppercase characters, 2 numbers, 1 special char: ${'-_+=#@&^()*/><{}?|`~,.'} and 13 chars min legnth`}
          value={this.state.object.password || ""}
          onChange={this.onChangePass}
          required
          fullWidth
          autoComplete=""
          autoCorrect=""
          error={passwordError}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <>
              <Tooltip title="Watch password">
                  <IconButton
                    aria-label="Watch password"
                    onClick={this.changeType}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Generate random password.">
                  <IconButton
                    aria-label="Generate random password"
                    onClick={this.generatePassword}
                  >
                    <Refresh />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy password to clipboard">
                  <IconButton
                    aria-label="Copy password to clipboard"
                    onClick={this.copyToClipboard}
                  >
                    <ClipboardTextMultipleOutline />
                  </IconButton>
                </Tooltip>
             </>
            </InputAdornment>
          }}
        />}
        {(this.props.object===undefined || ((this.props.object.userID&&SessionStore.user.id!==this.props.object.userID )||(this.props.object.id&&SessionStore.user.id!==this.props.object.id ) ) )&&<FormControl label="Permissions">
          <FormGroup>
            <FormControlLabel
              label="Is active"
              control={
                <Checkbox
                  id="isActive"
                  checked={!!this.state.object.isActive||undefined}
                  onChange={this.onChange}
                  color="primary"
                  defaultChecked={(this.props.object===undefined)?true:undefined}
                />
              }
            />
            <Admin>
              <FormControlLabel
                  label="Is global admin"
                  control={
                    <Checkbox
                        id="isAdmin"
                        checked={!!this.state.object.isAdmin}
                        onChange={this.onChange}
                        color="primary"
                    />
                  }
              />
              <FormControlLabel
                  label="AD auth"
                  control={
                    <Checkbox
                        id="loginWithLdap"
                        checked={!!this.state.object.loginWithLdap}
                        onChange={this.onChange}
                        color="primary"
                    />
                  }
              />
            </Admin>
           
          </FormGroup>
        </FormControl>}
            {!SessionStore.isAdmin()&&SessionStore.isOrganizationAdmin( SessionStore.getOrganizationID() ) && <>
              {(!this.props.object ||  SessionStore.user.id!==this.props.object.userID)&&<FormControl fullWidth margin="normal">
                <FormControlLabel
                    label="User is organization admin"
                    control={
                      <Checkbox
                          id="isAdmin"
                          checked={!!this.state.object.isAdmin}
                          onChange={this.onChange}
                          color="primary"
                      />
                    }
                />
                <FormHelperText>An organization admin user is able to add and modify resources part of the organization.</FormHelperText>
              </FormControl>}
              {!!!this.state.object.isAdmin&&<FormControl fullWidth margin="normal">
                <FormControlLabel
                    label="User is device admin"
                    control={
                      <Checkbox
                          id="isDeviceAdmin"
                          checked={!!this.state.object.isDeviceAdmin}
                          onChange={this.onChange}
                          color="primary"
                      />
                    }
                />
                <FormHelperText>A device admin user is able to add and modify resources part of the organization that
                  are related to devices.</FormHelperText>
              </FormControl>}
              {!!!this.state.object.isAdmin&&<FormControl fullWidth margin="normal">
                <FormControlLabel
                    label="User is gateway admin"
                    control={
                      <Checkbox
                          id="isGatewayAdmin"
                          checked={!!this.state.object.isGatewayAdmin}
                          onChange={this.onChange}
                          color="primary"
                      />
                    }
                />
                <FormHelperText>A gateway admin user is able to add and modify gateways part of the
                  organization.</FormHelperText>
              </FormControl>}

            </>}
                   
      </Form>
    );
  }
}

const style = {
  userInfo:{
    display:'flex'
  },
  userInfoItem:{
    width:'max-content',
    marginBottom:'5px'
  }
}


UserForm = withStyles(style)(UserForm)
export default UserForm;
